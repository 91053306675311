import PropTypes from 'prop-types';

import Recommender from 'core/components/Recommender';
import H2 from 'core/components/H2';

import { withBreakpoint } from 'core/components/breakpoint';

import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import { Card2Type0Xs } from 'site/cards/Card2';
import { Card3Type1Xs } from 'site/cards/Card3';

import styles from './index.styl';

function SiteRecommender({ isMobile }) {
  const Card = isMobile
    ? Card3Type1Xs
    : Card2Type0Xs;

  return (
    <YandexMetrikaGoalSender
      click='chitayte_takzhe_desktop_click'
      show='chitayte_takzhe_desktop_show'
      checkScrollY
      ids={7378180}
    >
      <Recommender
        blockId={isMobile ? 'e43996dfa2f342d58ed05959afd0e552' : 'cf1deebd96644fc7a990b2cc3f12e012'}
        limit={4}
        title='Читайте также'
        headerComponent={H2}
        interitemSpacing={20}
        card={Card}
        itemClassName={styles.card}
        columns={4}
        grid
      />
    </YandexMetrikaGoalSender>
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
