import PropTypes from 'prop-types';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import Rubric from 'core/components/Rubric';

import withPageHocs from 'core/components/withPageHocs';

import { filterRequiredParams } from 'core/utils/api';

import RubricLayout from 'site/components/RubricLayout';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import SectionContent from './Content';

const limit = 10;


function RubricPage({ rawRubric, rawTopics }) {
  return (
    <RubricLayout>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        limit={limit}
        headerSpacing={0}
      >
        { ({ topics }) => <SectionContent topics={topics} /> }
      </Rubric>
    </RubricLayout>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit,
    include: filterRequiredParams([Card1, Card2, Card3, Card4], 'include'),
    fields: filterRequiredParams([Card1, Card2, Card3, Card4], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(RubricPage);
