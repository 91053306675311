import color from 'core/libs/color';

import Logo from 'site/components/Logo';

const colors = {
  layout: '#fff',
  content: '#f5f5f5',
  card: '#fff',
  active1: '#ffea00',
  active2: '#ffea00',
  accent: '#ff3e56',
  error: '#ff3e56',
  primary: '#262626',
  dark: '#262626',
  footer: '#000',
  input: '#fff',
  divider: '#e5e5e5',
  galleryInfo: '#000',
  cardReadMore: '#7d7d7d',
  get hint() {
    return color(this.primary).alpha(0.8).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.active1;
  },
  get darkGrey() {
    return this.hint;
  },
  get lightGrey() {
    return this.placeholder;
  },
};

const link = {
  tertiary: {
    idle: {
      color: colors.primary,
      decoration: 'none',
    },

    hover: {
      color: colors.primary,
      decoration: 'underline',
    },

    active: {
      color: colors.primary,
      decoration: 'underline',
    },

    visited: {
      color: colors.primary,
      decoration: 'none',
    },
  },
};

const button = {
  primary: {
    idle: {
      color: colors.primary,
      background: colors.active2,
      border: `2px solid ${colors.active1}`,
    },

    hover: {
      color: colors.primary,
      background: colors.active1,
      border: `2px solid ${colors.placeholder}`,
    },

    active: {
      color: colors.primary,
      background: colors.active1,
      border: `2px solid ${colors.placeholder}`,
    },

    disabled: {
      color: colors.primary,
      background: `${color(colors.active2).alpha(0.1).string()}`,
      border: `2px solid ${color(colors.primary).alpha(0.2).string()}`,
    },
  },
  secondary: {
    idle: {
      color: colors.primary,
      background: 'none',
      border: `2px solid ${colors.placeholder}`,
    },
    hover: {
      color: colors.primary,
      background: colors.active1,
      border: `2px solid ${colors.placeholder}`,
    },
    active: {
      color: colors.primary,
      background: colors.active1,
      border: `2px solid ${colors.placeholder}`,
    },
    disabled: {
      color: colors.primary,
      background: `${color(colors.primary).alpha(0.1).string()}`,
      border: `2px solid ${color(colors.primary).alpha(0.2).string()}`,
    },
  },
  quaternary: {
    idle: {
      color: colors.footer,
      background: colors.layout,
      border: '1px solid transparent',
    },

    hover: {
      color: colors.footer,
      background: colors.layout,
      border: '1px solid transparent',
    },

    active: {
      color: colors.footer,
      background: colors.layout,
      border: '1px solid transparent',
    },

    disabled: {
      color: colors.footer,
      background: colors.layout,
      border: '1px solid transparent',
    },
  },
};

const drum = {
  arrow: {
    fade: {
      outer: 'transparent',
    },
  },
};

const topicHeaderTexts = {
  subtitle: {
    color: colors.hint,
  },
  lead: {
    color: colors.hint,
  },
};

const paginatron = {
  outerButtons: {
    innerBorder: button.secondary.idle.border,
    disabled: {
      background: button.secondary.disabled.background,
      color: button.secondary.disabled.color,
    },
    hover: {
      background: button.secondary.hover.background,
      color: button.secondary.hover.color,
    },
  },
};

const gameCompare = {
  tolltip: {
    buttonType: 'quaternary',
  },
  progressbar: {
    iconFill: colors.card,
    background: colors.primary,
    strokeColor: colors.card,
  },
};

const controls = {
  button,
  link,
  drum,
  topicHeaderTexts,
  paginatron,
  gameCompare,
};

export default {
  icons: {
    logo: Logo,
  },
  colors,
  controls,
};
