import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import TopicTopline from 'core/components/TopicTopline';
import ColumnLayout from 'core/components/ColumnLayout';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import resolveRelationships from 'core/utils/relationships';

import SideAdv from 'site/components/SideAdv';
import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';

import {
  TopicContentIndent,
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

const relationships = resolveRelationships(['content'], {});


function LeaderBoard({ content, isMobile }) {
  return (
    <ViewportHeightWrapper>
      <TopicWrapper>
        <Topic content={content}>
          {topic => {
            const {
              content: {
                widgets,
              },
            } = relationships(topic);

            if (widgets.length === 0) return null;

            const {
              final_announce: finalAnnounce,
            } = widgets[0].attributes;

            return (
              <ColumnLayout rightColumn={<SideAdv />}>
                <TopicContentIndent>
                  <VerticalIndent indent={20} />
                  <TopicTopline />
                  <VerticalIndent indent={isMobile ? 16 : 20} />
                  <TopicHeader content={topic} />
                  <VerticalIndent indent={20} />
                  <div>{finalAnnounce}</div>
                  <VerticalIndent indent={isMobile ? 35 : 25} />
                  <NegativeMobileSideIndent>
                    <LeaderBoardCore content={topic} widget={widgets[0]} />
                  </NegativeMobileSideIndent>
                  <VerticalIndent indent={isMobile ? 80 : 50} />
                </TopicContentIndent>
              </ColumnLayout>
            );
          }}
        </Topic>
      </TopicWrapper>
    </ViewportHeightWrapper>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(withBreakpoint(LeaderBoard));
