import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import themeHOC from 'core/components/theme';
import Rug from 'site/components/Rug';

import styles from './index.styl';

const NativeStyles = ({ theme }) => (
  <Fragment>
    <style jsx global>{`
      .listing-spec-native.adf-wrapper
        .ad_native_title
          font bold 17px/1.17 ${theme.fonts.nuance}
          color ${theme.colors.primary}

        .ad_label__text
          font 300 14px/1 ${theme.fonts.text}
          color ${theme.colors.placeholder}

        .ad_native_img
          &:before
            padding-top ${100 / 1.5 + '%'}
    `}</style>
  </Fragment>
);

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function ContentSpecNative(Component) {
  return themeHOC(props => (
    <div className={cx(styles['listing-spec-native'], 'adf-wrapper listing-spec-native')}>
      <Rug>
        <NativeStyles theme={props.theme} />
        <Component {...props} />
      </Rug>
    </div>
  ));
}
