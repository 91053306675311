import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import AdWrapper from 'core/components/Ad/AdWrapper';
import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import { VerticalIndent } from 'site/components/Indents';

import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';

import { Card2Type1Ml } from 'site/cards/Card2';
import { Card4Type2 } from 'site/cards/Card4';

function Author(props) {
  const {
    isDesktop,
    author,
    topics,
  } = props;

  const cardComponent = isDesktop ? Card4Type2 : Card2Type1Ml;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv />}>
        <AuthorPage
          author={author}
          topics={topics}
          card={cardComponent}
          columns={1}
          interitemSpacing={isDesktop ? 16 : 10}
        />
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10} >
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,

  /** @ignore */
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: props => topicsFetcher({
    limit: 10,
    card: props.isDesktop ? Card4Type2 : Card2Type1Ml,
  })(props),
});

export default withBreakpoint(withPageHocs(dataProvider)(Author));
