import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const host = 'motor.ru';
const siteUrl = 'https://' + host;

/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig() {
  return {
    siteName: 'motor',
    host: resolve({
      '*': host,
      'staging': getEnv('SITE_HOST'),
    }),
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.motor.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/motor',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    pushWoosh: {
      applicationCode: resolve({
        '*': 'F8326-6D4D4',
        'staging': '0C4FA-E145D',
      }),
      safariWebsitePushID: 'web.rambler.motor',
      defaultNotificationTitle: 'Motor.ru',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    bannersForCapirsMonitor: [
      433127980,
      460313267,
    ],

    isUnityProject: true,

    schemaOrg: {
      organizationName: 'Мотор',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       *
       * Имя файла логотипа не должно содержать точки, кроме как перед
       * расширением, поэтому берем логотип из статики. (UCMS-2824)
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 250,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s — Motor',
      defaultTitle: 'Автомобильные новости России и мира, тест-драйвы автомобилей, автоспорт',
      meta: [
        { name: 'description',
          content: 'Ведущий сайт об автомобилях на русском языке. Новости, тест-драйвы, видео, автомобильная история.',
        },
      ],
    },

    ramblerCommentsAppId: resolve({
      'production': 184,
      '*': 192,
    }),

    ads: {
      siteId: '433126276',
      antiadblock: true,
    },

    counterIds: {
      googleAnalytics: 'UA-18447330-2',
      ga4: 'G-YH7JQGRZFL',
      tns: { account: 'rambler_ru', tmsec: 'motor_total' },
      mediascope: 'rambler_cid1100108-posid2155108/',
      top100: 395258,
      yandexMetrika: [7378180],
      liveInternet: { ids: ['Lenta', 'gazeta_all'] },
      mailru: 3090295,
      rcm: 'RCM-F576',
    },

    features: {
      enableRetina: true,
      forcedDesktopFromZen: false,
      disableBlurPreview: true,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
      enableCapirsMonitor: true,
    },

    disableRequestPredictions: true,

    specialLinks: [
      '/about',
      '/video2015/',
      '/pilot/',
      '/exports/rss',
    ],

    ampConfig: {
      startAmpDate: '2015-09-13',
    },

    spammer: {
      ramblerCrmProjectKey: 'motor',
      digestKey: 'digest',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
    },

    ramblerId: {
      rname: 'motor',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'vkontakte', link: 'https://vk.com/motorru' },
      { name: 'telegram', link: 'https://telegram.me/motorru' },
      { name: 'odnoklassniki', link: 'https://ok.ru/motorru' },
      { name: 'youtube', link: 'https://www.youtube.com/user/M0T0RRU' },
      { name: 'yandex_dzen', link: 'https://dzen.ru/motor?favid=1670' },
    ],
  };
}
