import PropTypes from 'prop-types';
import { compose, pure, withStateHandlers } from 'core/libs/recompose';

import Video from 'core/components/Video';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import { withRouter } from 'core/libs/router';

const relationships = resolveRelationships(['video'], {}, { video: {} });

function TopicMediaVideo(props) {
  const {
    video: {
      code, caption, provider,
    },
  } = relationships(props.content);

  return (
    <Video
      code={code}
      caption={caption}
      providerName={provider}
    />
  );
}

TopicMediaVideo.propTypes = {
  isMediaVisible: PropTypes.bool,
  mediaHandler: PropTypes.func,
  location: PropTypes.object.isRequired,
  content: modelPropTypes(topicAttributes).isRequired,
};

export default compose(
  pure,
  withRouter,
  withStateHandlers(
    () => ({ isMediaVisible: false }),
    { mediaHandler: () => () => ({ isMediaVisible: true }) }
  ),
)(TopicMediaVideo);
