import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';
import CoreGameCompareSkeleton from 'core/components/GameCompare/Skeleton';

import { TopicContentIndent } from 'site/components/Indents';


function GameCompareSkeleton({ isMobile }) {
  return (
    <TopicContentIndent>
      <Indent
        top={20}
        bottom={isMobile ? 10 : 20}
      >
        <TopicToplineSkeleton height={isMobile ? 26 : 22} />
        <Indent top={isMobile ? 16 : 20} />
        <TopicHeaderTextsSkeleton
          titleHeight={isMobile ? 60 : 150}
          leadHeight={isMobile ? 150 : 300}
        />
      </Indent>
      <CoreGameCompareSkeleton />
      <Indent top={15} />
    </TopicContentIndent>
  );
}

GameCompareSkeleton.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(GameCompareSkeleton);
