import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import {
  VerticalIndent,
} from 'site/components/Indents';

import CardsList from 'site/components/CardsList';

import Card1 from 'site/cards/Card1';

function SectionContent({ topics, isDesktop }) {
  return (
    <Fragment>
      <VerticalIndent indent={isDesktop ? 23 : 10} />
      <Card1 type={5} content={topics[0]} />
      <VerticalIndent indent={isDesktop ? 16 : 10} />
      <CardsList topics={topics.slice(1)} />
      <VerticalIndent indent={isDesktop ? 23 : 10} />
    </Fragment>
  );
}

SectionContent.propTypes = {
  isDesktop: PropTypes.bool,
  title: PropTypes.string,
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  tag: PropTypes.object,
};

export default withBreakpoint(SectionContent);
