import RobotoBold from './Roboto/Roboto-Bold.woff';
import RobotoBold2 from './Roboto/Roboto-Bold.woff2';
import RobotoMedium from './Roboto/Roboto-Medium.woff';
import RobotoMedium2 from './Roboto/Roboto-Medium.woff2';
import RobotoRegular from './Roboto/Roboto-Regular.woff';
import RobotoRegular2 from './Roboto/Roboto-Regular.woff2';
import RobotoLight from './Roboto/Roboto-Light.woff';
import RobotoLight2 from './Roboto/Roboto-Light.woff2';

import FormularBold from './Formular/Formular-Bold.woff';
import FormularBold2 from './Formular/Formular-Bold.woff2';


const fonts = {
  display: 'Formular, sans-serif',
  text: 'Roboto, Roboto-fallback, sans-serif',
  get nuance() {
    return this.display;
  },
  faces: [
    {
      fontFamily: 'Roboto',
      src: `url(${RobotoRegular2}) format('woff2'), url(${RobotoRegular}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      src: `url(${RobotoBold2}) format('woff2'), url(${RobotoBold}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 500,
      src: `url(${RobotoMedium2}) format('woff2'), url(${RobotoMedium}) format('woff')`,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 300,
      src: `url(${RobotoLight2}) format('woff2'), url(${RobotoLight}) format('woff')`,
    },
    {
      fontFamily: 'Formular',
      fontWeight: 'bold',
      src: `url(${FormularBold2}) format('woff2'), url(${FormularBold}) format('woff')`,
    },
    {
      fontFamily: 'Roboto-fallback',
      sizeAdjust: '100%',
      ascentOverride: '93%',
      src: 'local("Arial")',
    },
  ],
};

export default fonts;
