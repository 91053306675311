import React from 'react';
import PropTypes from 'prop-types';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { doubleDashesReplacer, textReplacer, isLegacyPost } from 'site/utils';
import { REG_DOUBLE_DASHES, REG_TRIPLE_DASHES } from 'site/constants';

function TopicHeader(props) {
  const {
    content: {
      attributes: {
        headline,
        announce,
        alternative_headline: alternativeHeadline,
        published_at: publishedAt,
      },
    },
  } = props;

  const isLegacy = isLegacyPost(publishedAt);

  const headlineWithReplacer = doubleDashesReplacer(headline, '<br />');

  const altHeadlineWithReplacer = textReplacer(alternativeHeadline, [[REG_DOUBLE_DASHES, '$1&ndash;$2'], [REG_TRIPLE_DASHES, '&mdash;']]);

  return (
    <TopicHeaderTexts
      title={headlineWithReplacer}
      subtitle={altHeadlineWithReplacer}
      {...!isLegacy && {
        lead: textReplacer(
          announce,
          [[REG_DOUBLE_DASHES, '$1&ndash;$2'], [REG_TRIPLE_DASHES, '&mdash;']]
        ),
      }}
    />
  );
}


TopicHeader.propTypes = {
  withAnnounce: PropTypes.bool,
  isBig: PropTypes.bool,
  allowCustomStyles: PropTypes.bool,
  content: modelPropTypes(topicAttributes).isRequired,
};

export default TopicHeader;
