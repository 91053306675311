import PropTypes from 'prop-types';

import { Redirect } from 'core/libs/router';
import { isSameYear, isSameDay } from 'core/libs/date-fns';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import H3 from 'core/components/H3';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import Page from 'core/components/Page';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { dateFormat } from 'core/utils/dates';
import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import {
  getPageDayFromMatch,
  getUrlWithDate,
  getPageDayFromApiLink,
  getDateFromMatch,
} from 'core/utils/url-helper';

import {
  VerticalIndent,
} from 'site/components/Indents';

import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';
import ArrowLeft from 'site/icons/ArrowLeft';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card4 from 'site/cards/Card4';

import { pulseBaseUrl } from 'site/constants';

import List from './List';
import styles from './index.styl';


function PulsePage(props) {
  const {
    rawTopics,
    isDesktop,
  } = props;

  const noTopics = !rawTopics?.data?.length;
  const nextLink = rawTopics?.links?.next;
  const nextDateString = getPageDayFromApiLink(nextLink);
  const nextUrl = getUrlWithDate(pulseBaseUrl, nextDateString);

  if (noTopics) {
    return <Redirect to={nextUrl} />;
  }

  const topics = denormalizeData(rawTopics);
  const firstTopicDateString = topics[0] && topics[0].attributes.published_at;
  const firstTopicDate = new Date(firstTopicDateString);
  const yearFormat = isSameYear(firstTopicDate, new Date()) ? '' : ' y';

  const isToday = isSameDay(firstTopicDate, new Date());
  const dateDescription = isToday ? 'сегодня' : dateFormat(firstTopicDateString, 'd MMMM y') + ' года';
  const dateTitle = isToday ? 'сегодня' : dateFormat(firstTopicDateString, 'yyyy/MM/dd');

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv />}>
        <ViewportHeightWrapper>
          <H1>Пульс</H1>
          <VerticalIndent indent={isDesktop ? 16 : 10} />
          <Page
            title={`Автомобильные новости России и мира — ${dateTitle} — Пульс`}
            description={`Российские и мировые автомобильные новости на ${dateDescription}. Все самые свежие события из мира автомобилей и последние результаты автоспортивных событий на Motor.ru`}
          >
            <div className={styles.date}>
              <H3>{dateFormat(firstTopicDateString, `d MMMM${yearFormat}`)}</H3>
            </div>
            <List topics={topics} />
            <VerticalIndent indent={isDesktop ? 20 : 10} />
            <Link to={nextUrl}
              type='secondary'
              className={styles.button}
            >
              <Button size='large' type='secondary'>
                <ArrowLeft width={21} height={16} />
                <span className={styles.bottonText}>
                  {nextDateString
                    ? dateFormat(nextDateString, 'EEEE, d MMMM')
                    : 'Последние новости'}
                </span>
              </Button>
            </Link>
          </Page>
        </ViewportHeightWrapper>
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10} >
          <FooterAds contextCount={2} />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

PulsePage.propTypes = {
  rawTopics: PropTypes.shape({
    data: PropTypes.array,
    links: PropTypes.object,
  }),
  match: PropTypes.object,
  isDesktop: PropTypes.bool,
};

/* eslint-disable react/prop-types */
const withRedirect = Component => props => {
  const {
    year,
    month = 1,
    day,
  } = props.match.params;

  // Если мы в корне пульса, то редирект не нужен
  if (!year) return <Component />;

  const date = getDateFromMatch(props.match.params);

  // Для невалидной даты делаем редирект на главную новостей
  if (!date) {
    return <Redirect to={pulseBaseUrl} />;
  }

  // Исправляем дубли вида 2018/1/1 на 2018/01/01
  if (`${month}${day}`.length !== 4) {
    return <Redirect to={getUrlWithDate(pulseBaseUrl, date)} />;
  }

  return <Component />;
};
/* eslint-enable react/prop-types */

const dataProvider = resolve({
  rawTopics(props) {
    const {
      bebopApi,
      renderError,
      redirectPath,
      match,
    } = props;

    if (redirectPath) return null;

    return bebopApi
      .getTopics({
        'page[day]': getPageDayFromMatch(match.params),
        // eslint-disable-next-line new-cap
        'page[time_zone]': Intl.DateTimeFormat().resolvedOptions().timeZone, // IANA
        include: filterRequiredParams([Card1, Card2, Card4], 'include'),
        fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
      })
      .catch(renderError);
  },
});

export default withRedirect(
  withPageHocs(dataProvider)(
    withBreakpoint(PulsePage)
  ));
