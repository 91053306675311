import PropTypes from 'prop-types';

import color from 'core/libs/color';

import { Block, Section } from 'core/components/Grid';
import H3 from 'core/components/H3';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes from 'core/utils/prop-types/model';

import Engine from 'site/icons/Engine';
import Transmission from 'site/icons/Transmission';
import Speed from 'site/icons/Speed';
import Weight from 'site/icons/Weight';

import {
  EarsIndents,
  InnerIndent,
  TopicContentIndent,
} from 'site/components/Indents';

import styles from './index.styl';


export default function Summary(props) {
  const {
    parentProps: {
      theme,
    },
    block,
  } = props;

  const {
    attributes: {
      car_name: carName,
      like,
      dislike,
      verdict,
      engine,
      transmission,
      numbers,
      weight,
    },
  } = block;

  const verdictData = [
    { title: 'Нравится', text: like },
    { title: 'Не нравится', text: dislike },
    { title: 'Вердикт', text: verdict },
  ];

  const detailsData = [
    { icon: Engine, text: engine },
    { icon: Transmission, text: transmission },
    { icon: Speed, text: numbers },
    { icon: Weight, text: weight },
  ];

  return (
    <EarsIndents>
      <div className='summary'>
        <style jsx>{`
          .summary
            background ${color(theme.colors.primary).alpha(0.1).string()}
          .title
            font ${theme.texts.quote.font}
          .text
            font ${theme.texts.body.font}
        `}</style>

        <TopicContentIndent>
          <div className={styles.innerWrapper}>
            <div className={styles.carName}>
              <H3>{carName}</H3>
            </div>
            <Section>
              <Block mobile={12}>
                {verdictData.map(({ title, text }, index) => (
                  <div className={styles.verdict} key={index}>
                    <div className='title'>{title}</div>
                    <div className='text'>{text}</div>
                  </div>
                ))}
              </Block>
              <InnerIndent />
              <Block mobile={12}>
                {detailsData.map(({ icon: Icon, text }, index) => (
                  <div className={styles.details} key={index}>
                    <div className={styles.icon}>
                      <Icon />
                    </div>
                    <div className={styles.text}>
                      <MarkdownWrapper children={text} inline />
                    </div>
                  </div>
                ))}
              </Block>
            </Section>
          </div>
        </TopicContentIndent>
      </div>
    </EarsIndents>
  );
}

Summary.propTypes = {
  block: modelPropTypes(
    PropTypes.shape({
      car_name: PropTypes.string,
      like: PropTypes.string,
      dislike: PropTypes.string,
      verdict: PropTypes.string,
      engine: PropTypes.string,
      transmission: PropTypes.string,
      numbers: PropTypes.string,
      weight: PropTypes.string,
    })
  ),
  parentProps: PropTypes.object,
};
