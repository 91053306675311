import modelPropTypes, { imageAttributes } from 'core/utils/prop-types/model';
import coreSlides from 'core/components/TopicContent/blocks/slides';
import { EarsIndents } from 'site/components/Indents';

export default function Slides({ block }) {
  return (
    <EarsIndents>
      {coreSlides(block)}
    </EarsIndents>
  );
}

Slides.propTypes = {
  block: modelPropTypes(imageAttributes).isRequired,
};

