export default {
  0: {
    showDateRubric: true,
  },
  1: {},
  2: {
    showDateRubric: true,
    isAuthorPage: true,
  },
};
