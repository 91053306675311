import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import GalleryWithAds from 'site/components/GalleryWithAds';
import { EarsIndents } from 'site/components/Indents';

export default function PhotoGallery({ block }) {
  return (
    <EarsIndents>
      <GalleryWithAds photoGallery={block} />
    </EarsIndents>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
};
