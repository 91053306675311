import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import TopicTopline from 'core/components/TopicTopline';
import GameCompare from 'core/components/GameCompare';
import ColumnLayout from 'core/components/ColumnLayout';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import {
  VerticalIndent,
  TopicContentIndent,
} from 'site/components/Indents';
import SideAdv from 'site/components/SideAdv';

import TopicWrapper from '../TopicWrapper';
import TopicHeader from '../TopicHeader';


function TopicGameCompare({ content, isMobile }) {
  return (
    <TopicWrapper>
      <ColumnLayout rightColumn={<SideAdv />}>
        <TopicContentIndent>
          <VerticalIndent indent={20} />
          <TopicTopline />
          <VerticalIndent indent={isMobile ? 16 : 20} />
          <TopicHeader content={content} />
          <VerticalIndent indent={isMobile ? 10 : 20} />
        </TopicContentIndent>
        <TopicContentIndent>
          <GameCompare content={content} />
          <VerticalIndent indent={20} />
        </TopicContentIndent>
      </ColumnLayout>
    </TopicWrapper>
  );
}

TopicGameCompare.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicGameCompare);
