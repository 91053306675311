import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';


import { NegativeMobileSideIndent, VerticalIndent } from 'site/components/Indents';

import SelfPromo from 'core/components/SelfPromo';
import { Context } from 'site/components/Ads/desktop';
import { Footer } from 'site/components/Ads/mobile';

export default function FooterAds({ contextCount }) {
  return (
    <Fragment>
      <Mobile>
        <SelfPromo />
        <VerticalIndent indent={10} />
        <NegativeMobileSideIndent>
          <Footer />
        </NegativeMobileSideIndent>
      </Mobile>
      <Desktop>
        <Context count={contextCount} />
      </Desktop>
    </Fragment>
  );
}

FooterAds.propTypes = {
  /**
   * Устанавливает порядковый номер puid44 для рекламного места Context
   */
  contextCount: PropTypes.number,
};

FooterAds.defaultProps = {
  contextCount: 1,
};

