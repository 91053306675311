import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsFetcher } from 'core/fetchers/listPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import modelPropTypes, {
  rubricAttributes,
} from 'core/utils/prop-types/model';
import { replaceCurrentYear } from 'core/utils/string-helper';

import ListPage from 'core/components/ListPage';
import Page from 'core/components/Page';
import { PageIndent, Indent } from 'core/components/Wrappers';
import H1 from 'core/components/H1';

import RubricTopics from './RubricTopics';
import RubricLayout from 'site/components/RubricLayout';

import { GAME_COMPARE_LIMIT } from 'core/components/GameCompare/constants';

import styles from './index.styl';


function GameCompareRubric({ rubric, rawContent }) {
  const {
    data: {
      attributes: {
        description,
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    },
  } = rubric;

  const titleMeta = replaceCurrentYear(metaTitle);
  const descriptionMeta = replaceCurrentYear(metaDescription);

  return (
    <Page
      title={titleMeta || 'Автомобильные новости России и мира, тест-драйвы автомобилей, автоспорт'}
      description={descriptionMeta}
    >
      <PageIndent>
        <RubricLayout>
          <ListPage
            rawData={rawContent}
            limit={GAME_COMPARE_LIMIT}
          >
            {({ content }) => (
              <Fragment>
                <H1>Рейтинги на Motor.ru</H1>
                <Indent bottom={20} />
                {description &&
                  <Indent bottom={30}>
                    <div className={styles.description}>{description}</div>
                  </Indent>
                }
                <RubricTopics topics={content} />
              </Fragment>
            )}
          </ListPage>
        </RubricLayout>
      </PageIndent>
    </Page>
  );
}

GameCompareRubric.propTypes = {
  rubric: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
  }),
  rawContent: PropTypes.object,
};


const dataProvider =  resolve({
  rubric(props) {
    const {
      bebopApi,
      rubric,
      render404,
    } = props;

    return rubric || bebopApi
      .getRubric({
        rubric_slug: 'ratings',
      })
      .catch(render404);
  },

  rawContent: rawTopicsFetcher({
    limit: GAME_COMPARE_LIMIT,
    include: 'content',
    fields: 'link,headline,listHeadline,published_at',
    topic_type: 'game_compare,pair_compare',
  }),
});

export default withPageHocs(dataProvider)(GameCompareRubric);
