import deepmerge from 'core/libs/deepmerge';

import { FooterLogo } from './base';

import { Card3Type6Xs } from 'site/cards/Card3';

import {
  BLOCK_MOBILE_VERTICAL,
  BLOCK_MOBILE_HORIZONTAL,
} from 'site/components/Indents';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `300 18px/26px ${fonts.text}`,
    },

    body: {
      font: `300 16px/24px ${fonts.text}`,
    },

    h1: {
      font: `700 24px/26px ${fonts.display}`,
    },

    h2: {
      font: `700 24px/26px ${fonts.display}`,
    },

    h3: {
      font: `700 18px/24px ${fonts.display}`,
    },

    h4: {
      font: `700 16px/18px ${fonts.display}`,
    },

    h5: {
      font: `700 14px/16px ${fonts.text}`,
    },

    caption1: {
      font: `300 12px/16px ${fonts.text}`,
    },

    quote: {
      font: `700 16px/22px ${fonts.display}`,
    },

    topicInfo: { // Info, Date, Rubric
      font: `700 12px/14px ${fonts.display}`,
    },

    author: {
      font: `300 14px/16px ${fonts.text}`,
    },

    authorSmall: {
      font: `300 14px/16px ${fonts.text}`,
    },

    imageDescription: {
      font: `300 12px/16px ${fonts.text}`,
    },

    imageCopyright: {
      font: `300 14px/16px ${fonts.text}`,
    },

    coverCopyright: {
      font: `300 14px/16px ${fonts.text}`,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '15px 0 15px 15px',
      borderRadius: 0,
    },
    title: {
      marginBottom: '15px',
    },
    drum: {
      itemWidth: 282,
      spaceBetween: 10,
    },
  };

  const vikontIncut = {
    borderWidth: '0 0 0 6px',
    padding: '0 0 0 14px',
    font: texts.quote.font,
  };

  const scooter = {
    padding: '0 10px',
    logo: {
      Icon: FooterLogo,
      width: '102px',
      height: '25px',
    },
    menuLink: {
      font: `400 16px/18px ${fonts.text}`,
      textTransform: 'normal',
    },
    social: {
      margin: 0,
    },
  };

  const topicTopline = {
    font: `bold 12px/1 ${fonts.nuance}`,
  };

  const errorPage = {
    imgWidth: 300,
    title: {
      font: `32px/36px ${fonts.display}`,
    },
    subtitle: {
      font: `18px/25px ${fonts.text}`,
    },
    card: Card3Type6Xs,
    padding: '40px 0 0 ',
  };

  const shapka = {
    link: {
      padding: '10px 0',
    },
    dropdown: {
      link: {
        padding: '10px 0',
      },
      background: atoms.colors.layout,
    },
    logo: {
      width: 102,
      height: 25,
    },
    height: 50,
  };

  const expert = {
    name: {
      idle: {
        font: `500 10px/12px ${fonts.display}`,
      },
      hover: {
        font: `500 10px/12px ${fonts.display}`,
      },
    },
    jobTitle: {
      font: `400 12px/18px ${fonts.text}`,
    },
    label: {
      font: `500 10px/8px ${fonts.text}`,
    },
  };


  const topicFooter = {
    commentButton: {
      topSpacing: BLOCK_MOBILE_VERTICAL,
    },
    block: {
      spacing: BLOCK_MOBILE_VERTICAL,
    },
  };

  const storyCard = {
    headline: {
      font: `bold 12px/15px ${fonts.display}`,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `700 26px/32px ${fonts.display}`,
    },
    lead: {
      font: `18px/22px ${fonts.text}`,
    },
  };

  const vikontImage = {
    maxHeight: 1306,
    maxWidth: 908,
  };

  const commentsPreview = {
    headline: {
      font: texts.h2.font,
    },
    name: {
      font: `bold 14px/18px ${fonts.text}`,
    },
    button: {
      margin: '5px 0 20px 0',
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 16px/20px ${fonts.display}`,
      },
      info: {
        font: `700 12px/17px ${fonts.text}`,
      },
    },
  };

  const topicHeaderTexts = {
    subtitle: {
      font: `400 20px/26px ${fonts.text}`,
    },
    title: texts.h1,
  };

  const layout = {
    indents: {
      top: BLOCK_MOBILE_VERTICAL,
      right: BLOCK_MOBILE_HORIZONTAL,
      bottom: BLOCK_MOBILE_VERTICAL,
      left: BLOCK_MOBILE_HORIZONTAL,
    },
  };

  const gameCompare = {
    leaderBoard: {
      count: {
        background: '#686868',
      },
      header: {
        textTransform: 'none',
      },
      pairCompare: {
        margin: '40px 0 40px',
      },
    },
    winner: {
      strokeFill: atoms.colors.content,
      background: atoms.colors.footer,
      padding: '25px 0 25px',
    },
    share: {
      marginTop: '60px',
    },
  };

  const captionCredits = {
    padding: '0 10px',
  };

  const reactionsEmojiBar = {
    title: {
      color: atoms.colors.primary,
      font: `700 16px/20px ${fonts.display}`,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      vikontIncut,
      scooter,
      shapka,
      expert,
      topicFooter,
      storyCard,
      storyWidgetsBaseCard,
      topicTopline,
      topicHeaderTexts,
      commentsPreview,
      vikontImage,
      smartTeaser,
      gameCompare,
      reactionsEmojiBar,
      captionCredits,
    },
    pages: {
      error: errorPage,
      notFound: errorPage,
      sentryFallback: {
        ...errorPage,
        imgWidth: 750,
        card: null,
      },
    },
    layout,
    texts,
  }, atoms);
}
