import React from 'react';
import PropTypes from 'prop-types';

export default function ViewportHeightWrapper({ children }) {
  return (
    <div style={{ minHeight: '100vh' }}>
      {children}
    </div>
  );
}

ViewportHeightWrapper.propTypes = {
  children: PropTypes.node,
};
