import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import resolve from 'core/resolver/resolve';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import TagTopics from 'core/components/TagTopics';
import Bouesque from 'core/components/Bouesque';
import Mjolnir from 'core/components/Mjolnir';
import H5 from 'core/components/H5';
import { Mobile, Desktop } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withPageHocs from 'core/components/withPageHocs';
import bindPropsHOC from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import getBrandsFromQuto from 'site/fetchers/brands';

import SelfPromo from 'core/components/SelfPromo';
import { PartnersVertical } from 'site/components/Partners';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card4 from 'site/cards/Card4';

import {
  VerticalFirst,
  VerticalSecond,
} from 'site/components/Ads/desktop';

import {
  NegativeMobileSideIndent,
  VerticalIndent,
} from 'site/components/Indents';

import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import BrandsTags from 'site/components/BrandsTags';
import TopicsFilters from 'site/components/TopicsFilters';
import CardsList from 'site/components/CardsList';
import FooterAds from 'site/components/FooterAds';

import { filters as filtersData } from 'site/constants';

const Card1Type5 = bindPropsHOC({ type: 5 })(Card1);
const topicsLimit = 15;


function BrandPage({
  tag,
  topics: rawTopics,
  brands,
  tagRoot,
  subtags,
  isDesktop,
  match: {
    params: {
      level_2: slug,
    },
  },
}) {
  return (
    <PageIndent>
      <ColumnLayout
        rightColumn={(
          <>
            <Bouesque />
            <VerticalIndent indent={16} />
            <VerticalFirst />
            <VerticalIndent indent={16} />
            <SelfPromo />
            <VerticalIndent indent={16} />
            <PartnersVertical />
            <VerticalIndent indent={16} />
            <VerticalSecond />
            <VerticalIndent indent={16} />
          </>
        )}
      >
        <ViewportHeightWrapper>
          <TagTopics
            titleComponent={() => (
              <NegativeMobileSideIndent>
                <BrandsTags
                  brand={brands[0]}
                  brandsFilter={slug}
                  tag={tag}
                  tagRoot={tagRoot}
                  subtags={subtags}
                />
              </NegativeMobileSideIndent>
            )}
            tag={tag}
            topics={rawTopics}
            limit={topicsLimit}
          >
            {({ topics }) => {
              return (
                <>
                  <VerticalIndent indent={isDesktop ? 16 : 10} />
                  <TopicsFilters />
                  <VerticalIndent indent={isDesktop ? 16 : 10} />
                  {topics.length ? (
                    <>
                      <Desktop>
                        {topics.length > 4 ? (
                          <>
                            <Mjolnir
                              heroCard={Card1Type5}
                              card={Card1}
                              interitemSpacing={0}
                              content={topics.slice(0, 4)}
                            />
                            <VerticalIndent indent={isDesktop ? 16 : 10} />
                            <CardsList topics={topics.slice(4)} />
                          </>
                        ) : (
                          <>
                            <Card1 type={5} content={topics[0]} />
                            <VerticalIndent indent={isDesktop ? 16 : 10} />
                            <CardsList topics={topics.slice(1)} />
                          </>
                        )}
                      </Desktop>
                      <Mobile>
                        <VerticalIndent indent={10} />
                        <NegativeMobileSideIndent>
                          <Card1 type={5} content={topics[0]} />
                        </NegativeMobileSideIndent>
                        <VerticalIndent indent={isDesktop ? 16 : 10} />
                        <CardsList topics={topics.slice(1)} />
                      </Mobile>
                    </>
                  ) : <H5>Материалов по теме не найдено</H5>}
                  <VerticalIndent indent={isDesktop ? 23 : 10} />
                </>
              );
            }}
          </TagTopics>
        </ViewportHeightWrapper>
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10}>
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

BrandPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
  brands: PropTypes.array,
  tagRoot: PropTypes.object,
  subtags: PropTypes.array,
  isDesktop: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_2: PropTypes.string,
    }),
  }).isRequired,
};

const dataProvider = resolve({
  tag: tagFetcher(),

  topics: props => {
    const filters = queryString.parse(props.location.search);
    const isValidSlug = filtersData.find((e) => e.slug === filters.slug);

    return topicsFetcher({
      limit: topicsLimit,
      include: filterRequiredParams([Card1, Card2, Card4], 'include'),
      fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
      ...Object.keys(filters).length && isValidSlug && { [filters.type]: filters.slug },
    })(props);
  },

  brands: props => getBrandsFromQuto({
    limit: 1,
  })({ ...props, brandsFilter: props.match.params.level_2 }),

  tagRoot: (props) => {
    const {
      bebopApi,
      renderError,
      match: { params: { level_2: level2 } },
    } = props;

    return bebopApi
      .getTag({
        tag_slug: level2,
      })
      .then(denormalizeData)
      .catch(renderError);
  },

  subtags: (props) => {
    const {
      bebopApi,
      render404,
      match: { params: { level_2: level2 } },
    } = props;

    return bebopApi
      .getTags({
        tag_root: level2,
      })
      .then(denormalizeData)
      .catch(render404);
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(BrandPage));
