import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import CoreSearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import SideAdv from 'site/components/SideAdv';

import {
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecPulseSearch,
} from 'site/components/Ads/mobile';

import Card3, { Card3Type4M, Card3Type5Xs } from 'site/cards/Card3';

import filters from './filters';

const Ad2Mobile = props => (
  <NegativeMobileSideIndent>
    <ListingSpecPulseSearch {...props} />
  </NegativeMobileSideIndent>
);


function SearchPage(props) {
  const {
    rawContent,
    isMobile,
  } = props;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv withBouesque={false} />}>
        <CoreSearchPage
          rawContent={rawContent}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={Ad2Mobile}
          card={isMobile ? Card3Type5Xs : Card3Type4M}
          interitemSpacing={isMobile ? 10 : 16}
        />
      </ColumnLayout>
    </PageIndent>
  );
}

SearchPage.propTypes = {
  rawContent: PropTypes.array,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: Card3 }),
});

export default withPageHocs(dataProvider)(withBreakpoint(SearchPage));
