import { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import color from 'core/libs/color';
import themeHOC from 'core/components/theme';

import styles from './index.styl';

const IMAGE_RATIO = 3 / 2;
const imageHeight = 264;

const NativeStyles = ({ theme }) => {
  const borderColor = color(theme.colors.primary).alpha(0.1).string();

  return (
    <Fragment>
      <style jsx global>{`
        .adf-wrapper-native-card-4
          [data-render-state='rendered']
            border ${`1px solid ${borderColor}`}
            box-shadow 0 1px 2px 0 ${borderColor}
            height ${imageHeight}px

          .ad_native_wrapper
            > a
              transition background-color ${theme.animations.hover}
              background-color ${theme.colors.card}

              &:hover
                background-color ${color(theme.colors.active1).lighten(0.5).rgb().string()}

          .ad_native_title
            font-family ${theme.fonts.text}

          .ad_native_desc
            font-family ${theme.fonts.text}
            color ${theme.colors.hint}

          .ad_label__text
            font-family ${theme.fonts.display}
            color ${theme.colors.placeholder}

          .ad_native_img
            width ${Math.floor(imageHeight * IMAGE_RATIO)}px
            img
              background-color ${theme.colors.active1}
      `}</style>
    </Fragment>
  );
};

NativeStyles.propTypes = {
  theme: PropTypes.object,
};

export default function card4Styles(Component) {
  return themeHOC(props => (
    <div className={cx('adf-wrapper adf-wrapper-native-card-4', styles['adf-wrapper-native-card-4'])}>
      <NativeStyles theme={props.theme} />
      <Component {...props} />
    </div>
  ));
}
