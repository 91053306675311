export default {
  0: {
    showTime: true,
    showImage: true,
    showBg: true,
    height: 100,
    imageRatio: 1,
  },
  1: {
    showImage: true,
    showBg: true,
    height: 100,
    imageRatio: 1.5,
  },
  2: {
    showTime: true,
    imageRatio: 1,
  },
  3: {
    showDivider: true,
    showTime: true,
    imageRatio: 1,
  },
  4: {
    showImage: true,
    showBg: true,
    showAltHeadline: true,
    showTime: true,
    height: 140,
    imageRatio: 1.5,
  },
  5: {
    showImage: true,
    showBg: true,
    showTime: true,
    height: 100,
    imageRatio: 1,
  },
  6: {
    showImage: true,
    imageAtLeft: true,
    height: 100,
    imageRatio: 1,
  },
  7: {
    showTime: true,
    showImage: true,
    showBg: true,
    height: 100,
    imageRatio: 1.5,
  },
};
