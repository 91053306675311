import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function DarkWrapper({ theme, children }) {
  return (
    <div className={styles.layoutBackground}>
      <style jsx>{`
        .${styles.layoutBackground}
          &:before
            background ${theme.colors.content}
            @media screen and (max-width: ${theme.layout.minWidth})
              left 0
      `}</style>
      {children}
    </div>
  );
}

DarkWrapper.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(DarkWrapper);
