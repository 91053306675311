import PropTypes from 'prop-types';

import { getOffset } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import H1 from 'core/components/H1';

import {
  VerticalIndent,
} from 'site/components/Indents';

import RubricLayout from 'site/components/RubricLayout';
import CardsList from 'site/components/CardsList';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card4 from 'site/cards/Card4';

import ListPage from 'core/components/ListPage';

const limit = 10;


function MediaSectionPage(props) {
  const {
    rawTopics,
    match: {
      params: {
        level_1: level1,
      },
    },
    isDesktop,
  } = props;

  const topicTypeMap = {
    photo: {
      title: 'Фото',
      description: 'Фотографии автомобилей на Motor.ru',
    },
    video: {
      title: 'Видео',
      description: 'Автомобильное видео на Motor.ru',
    },
  };

  return (
    <RubricLayout>
      <ListPage
        title={`${topicTypeMap[level1].title} — все статьи и новости`}
        description={topicTypeMap[level1].description}
        rawData={rawTopics}
        limit={limit}
      >
        {({ content }) => (
          <>
            <H1>{topicTypeMap[level1].title}</H1>
            <VerticalIndent indent={isDesktop ? 23 : 10} />
            <Card1 type={5} content={content[0]} />
            <VerticalIndent indent={isDesktop ? 16 : 10} />
            <CardsList topics={content.slice(1)} />
            <VerticalIndent indent={isDesktop ? 23 : 10} />
          </>
        )}
      </ListPage>
    </RubricLayout>
  );
}

MediaSectionPage.propTypes = {
  rawTopics: PropTypes.object,
  isDesktop: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const dataProvider = resolve('rawTopics', props => {
  const {
    bebopApi,
    renderError,
    location: { search },
    match: {
      params: {
        level_1: level1,
      },
    },
  } = props;

  const topicTypeMap = {
    photo: 'gallery',
    video: 'video',
  };

  const filterType = level1 === 'video'
    ? { tag: topicTypeMap[level1] }
    : { topic_type: topicTypeMap[level1] };

  return bebopApi
    .getTopics({
      limit,
      offset: getOffset(search, limit),
      include: filterRequiredParams([Card1, Card2, Card4], 'include'),
      fields: filterRequiredParams([Card1, Card2, Card4], 'fields'),
      with_filtered_count: 1,
      ...filterType,
    })
    .catch(renderError);
});

export default withPageHocs(dataProvider)(withBreakpoint(MediaSectionPage));
