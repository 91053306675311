import { Fragment } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, { imageAttributes } from 'core/utils/prop-types/model';

import coreImage from 'core/components/TopicContent/blocks/image';

import { EarsIndents } from 'site/components/Indents';


export default function Image({
  block,
  parentProps,
  ctx,
}) {
  const {
    align,
    versions: {
      original,
    },
  } = block.attributes;
  const vikontImageAtoms = parentProps.theme.controls.vikontImage;

  return (
    <Fragment>
      {vikontImageAtoms.maxWidth > original.width || /(left|right)/i.test(align) ? (
        coreImage(block, parentProps, ctx)
      ) : (
        <EarsIndents>
          {coreImage(block, parentProps, ctx)}
        </EarsIndents>
      )}
    </Fragment>
  );
}

Image.propTypes = {
  block: modelPropTypes(imageAttributes).isRequired,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
