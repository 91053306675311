import color from 'core/libs/color';

import bindPropsHOC from 'core/components/bindProps';

import Logo from 'site/components/Logo';

const colors = {
  layout: '#1c1c1c',
  content: '#1c1c1c',
  active1: '#ffea00',
  primary: '#fff',
  dark: '#262626',
  darkGrey: '#373737',
  card: '#fff',
  get active2() {
    return this.hint;
  },
  accent: '#ff3e56',
  error: '#ff3e56',
  footer: '#000',
  input: '#fff',
  divider: '#d9d9d9',
  galleryInfo: '#fff',
  cardReadMore: '#fff',
  grey: '#7a7a7a',
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.8).string();
  },
  get theme() {
    return this.active1;
  },
  get lightGrey() {
    return color(this.dark).alpha(0.3).string();
  },
};

const link = {
  tertiary: {
    idle: {
      color: colors.hint,
      decoration: 'none',
    },

    hover: {
      color: colors.hint,
      decoration: 'underline',
    },

    active: {
      color: colors.hint,
      decoration: 'none',
    },

    visited: {
      color: colors.hint,
      decoration: 'none',
    },
  },

  secondary: {
    idle: {
      color: colors.active2,
      decoration: 'none',
    },

    hover: {
      color: colors.primary,
      decoration: 'none',
    },

    active: {
      color: colors.active2,
      decoration: 'none',
    },

    visited: {
      color: colors.active2,
      decoration: 'none',
    },
  },
};

const button = {
  primary: {
    idle: {
      color: colors.content,
      background: colors.active1,
      border: `2px solid ${colors.active1}`,
    },

    hover: {
      color: colors.content,
      background: colors.active1,
      border: `2px solid ${color(colors.content).alpha(0.3).string()}`,
    },

    active: {
      color: colors.content,
      background: colors.active1,
      border: `2px solid ${color(colors.content).alpha(0.3).string()}`,
    },

    disabled: {
      color: colors.placeholder,
      background: `${color(colors.active1).alpha(0.2).string()}`,
      border: `2px solid ${color(colors.active1).alpha(0.8).string()}`,
    },
  },

  secondary: {
    idle: {
      color: colors.primary,
      background: 'none',
      border: `2px solid ${colors.placeholder}`,
    },

    hover: {
      color: colors.content,
      background: colors.active1,
      border: `2px solid ${colors.active1}`,
    },

    active: {
      color: colors.content,
      background: colors.active1,
      border: `2px solid ${colors.active1}`,
    },

    disabled: {
      color: colors.placeholder,
      background: `${color(colors.active1).alpha(0.2).string()}`,
      border: `2px solid ${color(colors.active1).alpha(0.8).string()}`,
    },
  },

  tertiary: {
    idle: {
      color: colors.content,
      background: colors.primary,
      border: `1px solid ${colors.divider}`,
    },

    hover: {
      color: colors.content,
      background: colors.active1,
      border: `1px solid ${color(colors.active1).alpha(0.3).string()}`,
    },

    active: {
      color: colors.content,
      background: colors.active1,
      border: `1px solid ${color(colors.content).alpha(0.3).string()}`,
    },

    disabled: {
      color: colors.divider,
      background: `${color(colors.dark).alpha(0.1).string()}`,
      border: `1px solid ${color(colors.dark).alpha(0.3).string()}`,
    },
  },

  ghost: {
    idle: {
      color: colors.content,
    },
    disabled: {
      color: colors.divider,
    },
  },

  quaternary: {
    idle: {
      color: color(colors.primary).alpha(0.7).string(),
      background: color(colors.divider).alpha(0.2).string(),
      border: '1px solid transparent',
    },

    hover: {
      color: colors.primary,
      background: color(colors.divider).alpha(0.2).string(),
      border: `1px solid ${color(colors.active1).alpha(0.3).string()}`,
    },

    active: {
      color: colors.primary,
      background: color(colors.divider).alpha(0.2).string(),
      border: `1px solid ${color(colors.active1).alpha(0.3).string()}`,
    },

    disabled: {
      color: colors.placeholder,
      background: color(colors.divider).alpha(0.2).string(),
      border: `1px solid ${colors.active1}`,
    },
  },
};

const gallery = {
  arrows: {
    idle: {
      fill: color(colors.primary).alpha(0.4).string(),
    },

    hover: {
      fill: color(colors.primary).alpha(0.9).string(),
    },
  },
  icons: {
    background: color(colors.content).alpha(0.75).string(),
  },
};

const fontSizeChangerButtons = {
  filter: 'invert(1)',
};

const paginatron = {
  icon: {
    idle: {
      fill: button.ghost.idle.color,
    },
    disabled: {
      fill: colors.lightGrey,
    },
  },
  text: {
    color: button.tertiary.idle.color,
  },
  background: button.tertiary.idle.background,
  outerButtons: {
    innerBorder: button.tertiary.idle.border,
    idle: {
      background: button.tertiary.idle.background,
      color: button.tertiary.idle.color,
    },
    disabled: {
      background: button.tertiary.disabled.background,
      color: button.tertiary.disabled.color,
    },
    hover: {
      background: button.tertiary.hover.background,
      color: button.tertiary.hover.color,
    },
  },
};

const login = {
  popup: {
    content: {
      color: colors.primary,
    },
  },
};

const input = {
  const: {
    color: colors.primary,
  },
  basic: {
    idle: {
      background: colors.darkGrey,
      border: `1px solid ${colors.darkGrey}`,
    },

    hover: {
      background: colors.darkGrey,
      border: `2px solid ${colors.divider}`,
    },

    focused: {
      background: colors.darkGrey,
      border: `2px solid ${colors.divider}`,
    },

    disabled: {
      border: `2px solid ${colors.divider}`,
    },

    error: {
      background: colors.darkGrey,
    },
  },
};

const gameCompare = {
  tooltip: {
    buttonType: 'quaternary',
  },
  compareCard: {
    lightningColor: colors.primary,
  },
};

const topicTopline = {
  rubric: {
    color: colors.active1,
  },
};

const socializator = {
  fixedPosition: false,
};

const storyCard = {
  headline: {
    color: colors.primary,
  },
};

const comments = {
  const: {
    themeMode: 'dark',
  },
};

const reactions = {
  topic: {
    idle: {
      color: colors.content,
      border: 'none',
      background: 'transparent',
    },
    checked: {
      color: colors.content,
      border: 'none',
      background: 'transparent',
    },
    focus: {
      color: colors.content,
      border: 'none',
      background: 'transparent',
    },
    disabled: {
      color: color(colors.content).alpha(0.5).toString(),
      border: 'none',
      background: 'transparent',
    },
    checkedDisabled: {
      color: colors.content,
      border: 'none',
      background: 'transparent',
    },
  },
};

const reactionsEmojiBar = {
  const: {
    background: colors.active1,
    border: 'none',
  },

  title: {
    color: colors.content,
  },
};

const controls = {
  link,
  button,
  gallery,
  fontSizeChangerButtons,
  paginatron,
  input,
  login,
  gameCompare,
  topicTopline,
  socializator,
  storyCard,
  comments,
  reactions,
  reactionsEmojiBar,
};

export const DarkLogo = bindPropsHOC({ fill: colors.primary })(Logo);

export default {
  colors,
  icons: {
    logo: DarkLogo,
  },
  controls,
};
