import Gallery from 'core/components/Gallery';

import { InGallery } from 'site/components/Ads/desktop';
import { InGallery as InGalleryMobile } from 'site/components/Ads/mobile';

export default function GalleryWithAds(props) {
  return (
    <Gallery
      injectAds={{
        desktop: {
          component: InGallery,
          count: 5,
        },
        mobile: {
          component: InGalleryMobile,
          count: 5,
        },
      }}
      {...props}
    />
  );
}
