import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import cardHOC from 'core/components/card';
import bindProps from 'core/components/bindProps';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Rug from 'site/components/Rug';

import relationshipsPropTypes, {
  imagePropTypes,
  rubricPropTypes,
} from 'core/utils/prop-types/relationships';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import {
  imageVersionExists,
  doubleDashesReplacer,
  textReplacer,
  topicDateFormat,
} from 'site/utils';

import IconPhoto from 'site/icons/Photo';
import IconVideo from 'site/icons/Video';

import { REG_DOUBLE_DASHES, REG_TRIPLE_DASHES } from 'site/constants';

import mapTypes from './types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'rubric',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'topic_type',
  'published_at',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

const iconsMap = {
  video: IconVideo,
  card: IconPhoto,
};


function Card3(props) {
  const {
    content,
    theme,
    type,
    isMobile,
    size,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: publishedAt,
    alternative_headline: altHeadline,
    list_headline: listHeadline,
    topic_type: topicType,
  } = content.attributes;

  const modifyHeadline = doubleDashesReplacer(listHeadline || headline, !isMobile && '<br />');
  const modifyAltHeadline = textReplacer(altHeadline, [
    [REG_DOUBLE_DASHES, '$1&ndash;$2'],
    [REG_TRIPLE_DASHES, '&mdash;'],
  ]);

  const {
    image: {
      versions: {
        ['list_3/2']: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
    rubric: {
      root_title: rubricRoot,
    },
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    showAltHeadline,
    showTime,
    showImage,
    showDivider,
    showBg,
    imageAtLeft,
    imageRatio,
    height,
  } = mapTypes[type] || {};

  const iconSize = size === 'xs' ? 22 : 16;
  const Icon = iconsMap[topicType] || (() => null);

  const Wrapper = showBg ? Rug : 'div';

  const infoContent = showTime ? topicDateFormat(publishedAt) : rubricRoot;

  const cover = imageVersionExists(preCover) ? preCover : originalCover;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.headlines}
          background-color ${showBg ? theme.colors.card : 'transparent'}
          transition background-color ${theme.animations.hover}
          .${styles.link}:hover &
            background-color ${showBg ? color(theme.colors.active1).lighten(0.5).rgb().string() : 'initial'}

        .headline
          .${styles.link}:hover &
            text-decoration-color ${showBg ? 'transparent' : theme.colors.primary}
      `}</style>
    </scope>
  );

  return (
    <Wrapper>
      <style jsx>{`
        .${styles.overlayWrapper}
          flex 0 0 ${height * imageRatio}px

        .${styles.headline}
          font-family ${theme.fonts.text}
          color ${theme.colors.dark}
          transition text-decoration-color ${theme.animations.hover}

        .${styles.altHeadline}
          font-family ${theme.fonts.text}
          color ${theme.colors.darkGrey}

        .${styles.info}
          font-family ${theme.fonts.display}
          color ${theme.colors.lightGrey}

        .${styles.divider}
          margin-top 0
          background-color ${theme.colors.divider}

        .${styles.overlay}
          transition opacity ${theme.animations.hover}
      `}</style>
      <Link
        to={link}
        type='secondary'
        className={cx(
          styles.link,
          scoped.wrapClassNames('link'),
          styles[`_size_${size}`],
          imageAtLeft && styles._imageAtLeft,
        )}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        <div className={cx(styles.headlines, scoped.wrapClassNames('headlines'))}>
          {!!infoContent &&
            <div className={styles.info}>
              {infoContent}
            </div>
          }
          <div className={cx(styles.headline, scoped.wrapClassNames('headline'))}>
            <MarkdownWrapper children={modifyHeadline} inline />
          </div>
          {showAltHeadline &&
            <div className={styles.altHeadline}>
              <MarkdownWrapper children={modifyAltHeadline} inline />
            </div>
          }
        </div>
        {showImage &&
          <div className={styles.overlayWrapper}>
            <SmartImage
              {...cover && { src: cover }}
              {...remoteImage && { url: remoteImage }}
              {...cover && { previewSrc: previewCover }}
              maxWidth={imageRatio * (height || 100)}
              position='50% 0'
              aspectRatio={imageRatio}
            />
            <div className={styles.overlay} />
            <div className={styles.overlayContent}>
              <Icon
                width={iconSize}
                height={iconSize}
                className={styles.icon}
              />
            </div>
          </div>
        }
      </Link>
      {showDivider && <div className={styles.divider} />}
      <scoped.styles />
    </Wrapper>
  );
}

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7]),

  /** Размер карточки */
  size: PropTypes.oneOf(['xs', 's', 'm']),

  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
    rubric: rubricPropTypes(),
  }),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,
};

Card3.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card3);

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export { Card as Card3Type0S };
export const Card3Type1Xs = bindProps({ type: 1, size: 'xs' })(Card);
export const Card3Type2S = bindProps({ type: 2, size: 's' })(Card);
export const Card3Type3S = bindProps({ type: 3, size: 's' })(Card);
export const Card3Type4M = bindProps({ type: 4, size: 'm' })(Card);
export const Card3Type5Xs = bindProps({ type: 5, size: 'xs' })(Card);
export const Card3Type6Xs = bindProps({ type: 6, size: 'xs' })(Card);
export const Card3Type7S = bindProps({ type: 7, size: 's' })(Card);

export default Card;

export { Card3 as StorybookComponent };
