import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Divider from 'core/components/Divider';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import {
  VerticalIndent,
  TopicContentIndent,
  CONTENT_HORIZONTAL,
} from 'site/components/Indents';
import Share from 'site/components/Share';

import TopicWrapper from '../TopicWrapper';
import TopicContent from '../TopicContent';
import TopicFooter from '../TopicFooter';

import styles from './index.styl';


function PremiumTopic(props) {
  const {
    content,
    infinityIndex,
    theme: {
      controls: {
        topicHeaderTexts: atoms,
        topicContent: topicContentAtoms,
      },
    },
  } = props;

  const {
    id,
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
      is_premium: isPremiumTopic,
      announce,
    },
  } = content;

  const isPremiumInInfinity = infinityIndex !== 0;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.lead}
          font ${atoms.lead.font}
          color ${atoms.lead.color}
          letter-spacing ${atoms.lead.letterSpacing || 0}
      `}</style>
    </scope>
  );

  return (
    <TopicWrapper>
      <style jsx>{`
        .${styles.topicWrapper}
          max-width ${topicContentAtoms.widgetsMaxWidth}px

        .premiumInInfinity
          :global(.desktop) &
            margin 0 -${CONTENT_HORIZONTAL}px
      `}</style>
      <TopicContentIndent>
        <VerticalIndent indent={20} />
        {isPremiumInInfinity && (
          <div className='premiumInInfinity'>
            <PremiumTopicHeader
              content={content}
              isPremiumInInfinity
            />
          </div>
        )}
        <div className={styles.topicWrapper}>
          {!!announce && (
            <MarkdownWrapper inline className={scoped.wrapClassNames(styles.lead)}>
              {announce}
            </MarkdownWrapper>
          )}
          <VerticalIndent indent={20} />
          <Share
            xid={id}
            topicLink={link}
            isCommentsDisabled={isCommentsDisabled}
          />
          <VerticalIndent indent={25} />
          <Divider />
          <TopicContent content={content}  />
          <TopicFooter isPremiumTopic={isPremiumTopic} />
        </div>
      </TopicContentIndent>
      <scoped.styles />
    </TopicWrapper>
  );
}

PremiumTopic.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  infinityIndex: PropTypes.number,
  theme: PropTypes.object,
};

export default withTheme(PremiumTopic);
