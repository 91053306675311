export default {
  xs: {
    headlineSize: '14px',
    altHeadlineSize: '13px',
    iconSize: '22px',
    contentPadding: '8px 12px',
  },
  s: {
    headlineSize: '16px',
    altHeadlineSize: '14px',
    iconSize: '16px',
    contentPadding: '10px 12px',
  },
  sm: {
    headlineSize: '16px',
    altHeadlineSize: '14px',
    iconSize: '32px',
    contentPadding: '10px 12px',
  },
  m: {
    headlineSize: '17px',
    altHeadlineSize: '14px',
    iconSize: '16px',
    contentPadding: '10px 12px',
  },
  ml: {
    headlineSize: '17px',
    altHeadlineSize: '14px',
    iconSize: '40px',
    contentPadding: '10px 12px',
  },
  l: {
    headlineSize: '24px',
    altHeadlineSize: '16px',
    iconSize: '32px',
    contentPadding: '12px 16px',
  },
  xl: {
    headlineSize: '32px',
    altHeadlineSize: '16px',
    iconSize: '32px',
    contentPadding: '24px 20px',
  },
  huge: {
    headlineSize: '54px',
    altHeadlineSize: '18px',
    iconSize: '52px',
    contentPadding: '24px 20px',
  },
};
