import PropTypes from 'prop-types';

import AdWrapper from 'core/components/Ad/AdWrapper';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { VerticalIndent } from 'site/components/Indents';
import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';


function RubricLayout({ isDesktop, children }) {
  return (
    <>
      <PageIndent>
        <ColumnLayout rightColumn={<SideAdv />}>
          <ViewportHeightWrapper>
            {children}
          </ViewportHeightWrapper>
          <VerticalIndent indent={isDesktop ? 16 : 10} />
          <AdWrapper bottom={isDesktop ? 16 : 10} >
            <FooterAds />
          </AdWrapper>
        </ColumnLayout>
      </PageIndent>
    </>
  );
}

RubricLayout.propTypes = {
  isDesktop: PropTypes.bool,
  children: PropTypes.node,
};

export default withBreakpoint(RubricLayout);
