import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import { Helmet } from 'core/libs/helmet';
import { withRouter } from 'core/libs/router';

function BrandsMeta({
  title,
  type,
  location: {
    search,
  },
}) {
  const metaTypes = {
    'default': {
      'brand_title': `${title} — новости, фото, видео, тест-драйвы`,
      'brand_description': `Все об автомобилях ${title} на Motor.ru. Последние новости, тест-драйвы новых моделей ${title}, фото и видео`,
      'model_title': `${title} — новости, фото, видео, тест-драйвы`,
      'model_description': `Все об автомобиле ${title} на Motor.ru. Последние новости, тест-драйвы и обзоры различных комплектаций ${title}, фото и видео`,
    },
    'article': {
      'brand_title': `${title} — обзоры и статьи`,
      'brand_description': `Новости и статьи об автомобилях ${title} на Motor.ru. Свежие обзоры, советы по эксплуатации и мнения экспертов о новых моделях ${title}`,
      'model_title': `${title} — обзоры и статьи`,
      'model_description': `Статьи об автомобиле ${title} на Motor.ru. Свежие обзоры и мнения экспертов о ${title}`,
    },
    'news': {
      'brand_title': `${title} — обзоры и статьи`,
      'brand_description': `Новости и статьи об автомобилях ${title} на Motor.ru. Свежие обзоры, советы по эксплуатации и мнения экспертов о новых моделях ${title}`,
      'model_title': `${title} — обзоры и статьи`,
      'model_description': `Статьи об автомобиле ${title} на Motor.ru. Свежие обзоры и мнения экспертов о ${title}`,
    },
    'testdrives': {
      'brand_title': `Тест-драйвы ${title}`,
      'brand_description': `Тест-драйвы ${title} на Motor.ru. Профессиональные тест-драйвы новых автомобилей ${title} с видео и фото`,
      'model_title': `Тест-драйв ${title}`,
      'model_description': `Тест-драйв автомобиля ${title} на Motor.ru. Профессиональные тест-драйвы ${title} с видео и фото`,
    },
    'gallery': {
      'brand_title': `Фото ${title}`,
      'brand_description': `Качественные фото автомобилей ${title} на Motor.ru. Фотографии концептов и новых моделей ${title}`,
      'model_title': `Фото ${title}`,
      'model_description': `Качественные фото автомобиля ${title} на Motor.ru. Фотографии ${title} со всех ракурсов`,
    },
    'video': {
      'brand_title': `Видео ${title} — видеообзоры автомобилей`,
      'brand_description': `Видеообзоры автомобилей ${title} на Motor.ru. Качественное видео моделей ${title}`,
      'model_title': `Видео ${title} — видеообзоры автомобиля`,
      'model_description': `Видеообзоры автомобиля ${title} на Motor.ru. Качественное видео ${title}`,
    },
  };

  const filters = queryString.parse(search);
  const metaType = metaTypes[filters.slug] || metaTypes.default;

  return (
    <Helmet>
      <title>{metaType[`${type}_title`]}</title>
      <meta name='description' content={metaType[`${type}_description`]} />
    </Helmet>
  );
}

BrandsMeta.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'brand',
    'model',
  ]),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default withRouter(BrandsMeta);
