import PropTypes from 'prop-types';

import bindPropsHOC from 'core/components/bindProps';

import modelPropTypes from 'core/utils/prop-types/model';

import Card1 from 'site/cards/Card1';

import { EarsIndents } from 'site/components/Indents';

const Card1Type5 = bindPropsHOC({ type: 5 })(Card1);

export default function GalleryLink(props) {
  const {
    block,
  } = props;

  const content = block.attributes.gallery;

  const newContent = {
    ...content,
    relationships: {
      image: {
        data: content.attributes.image,
      },
    },
  };

  return (
    <EarsIndents>
      <Card1Type5 content={newContent} />
    </EarsIndents>
  );
}

GalleryLink.propTypes = {
  block: modelPropTypes(
    PropTypes.shape({
      gallery: PropTypes.object.isRequired,
    })
  ),
};
