const content = [
  {
    id: 'spec007',
    cover: require('./images/spec007.jpg'),
    headline: 'Тест: Детальный разбор',
    alternative_headline: 'Пройдите наш тест и узнайте, на детали чего вы обращаете большее внимание — техники или автомобилей.',
    link: 'http://bork.motor.ru/',
    width: 1200,
    height: 628,
  },
  {
    id: 'spec006',
    cover: require('./images/spec006.jpg'),
    headline: 'Пикап-мастер',
    alternative_headline: 'Думаете, что знаете о езде по бездорожью все и разбираетесь в нюансах преодоления водных, снежных и любых других преградна автомобиле? У вас есть отличная возможность проверить себя!',
    link: 'http://vwamarok.motor.ru/',
    width: 800,
    height: 420,
  },
  {
    id: 'spec005',
    cover: require('./images/spec005.jpg'),
    headline: '«Дакар» – главный новогодний марафон планеты',
    alternative_headline: 'Свежие новости гонки «Дакар», история главного марафона на планете, экстремальные зимние развлечения в стиле MINI, конкурс зимних фотографий и викторина в специальном проекте Мотор.ру.',
    link: 'http://dakar.motor.ru/',
    width: 1200,
    height: 800,
  },
  {
    id: 'spec004',
    cover: require('./images/spec004.jpg'),
    headline: 'В отпуск за рулем. Зачем? Почему? И как?',
    alternative_headline: 'Узнай главные лайфхаки путешествия за рулем.',
    link: 'http://travel.motor.ru/',
    width: 1200,
    height: 630,
  },
  {
    id: 'spec003',
    cover: require('./images/spec003.jpg'),
    headline: 'Машины, на которых вы никогда не прокатитесь',
    alternative_headline: 'Тест-драйвы уникальных автомобилей: видео. Профессиональные видеотесты необычной техники на Motor.ru',
    link: 'https://motor.ru/video2015/',
    width: 960,
    height: 640,
  },
  {
    id: 'spec002',
    cover: require('./images/spec002.jpg'),
    headline: 'Собери самый мощный двигатель',
    alternative_headline: 'Всегда хотел поковыряться в моторе, а тут такая возможность. Как ни странно - на Моторе!',
    link: 'http://mobil1.motor.ru/',
    width: 200,
    height: 200,
  },
  {
    id: 'spec001',
    cover: require('./images/spec001.png'),
    headline: 'Что умеет умный автомобиль — BMW ConnectedDrive',
    alternative_headline: 'Все, что вы хотели знать о системе ConnectedDrive от BMW, но боялись спросить',
    link: 'http://drive-bmw.motor.ru/',
    width: 300,
    height: 300,
  },
];

export default content.map(item => {
  return {
    id: item.id,
    type: 'topic',
    attributes: {
      topic_type: 'article',
      headline: item.headline,
      published_at: '2017-11-20T12:57:00.000Z',
      link: item.link,
      alternative_headline: item.alternative_headline,
    },
    relationships: {
      rubric: {
        data: {
          id: `${item}-rubric`,
          type: 'rubric',
          attributes: {
            slug: '',
            title: '',
            enabled: false,
            root_slug: '',
            root_title: '',
          },
        },
      },
      image: {
        data: {
          id: `${item}-cover`,
          type: 'image',
          attributes: {
            versions: {
              original: {
                rel_url: item.cover,
                width: item.width,
                height: item.height,
              },
            },
          },
        },
      },
    },
  };
});
