import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import AdWrapper from 'core/components/Ad/AdWrapper';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { VerticalIndent } from 'site/components/Indents';
import SideAdv from 'site/components/SideAdv';
import FooterAds from 'site/components/FooterAds';


function Authors({ rawAuthors, isDesktop }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<SideAdv />}>
        <H1>Авторы</H1>
        <VerticalIndent indent={30} />
        <AuthorsPage rawAuthors={rawAuthors} />
        <VerticalIndent indent={isDesktop ? 16 : 10} />
        <AdWrapper bottom={isDesktop ? 16 : 10} >
          <FooterAds />
        </AdWrapper>
      </ColumnLayout>
    </PageIndent>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
