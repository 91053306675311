import { denormalizeData } from 'core/utils/api';
import { resolve as resolveEnv } from 'core/utils/env';

export default (apiParams = {}) => ({ bebopApi, brandsFilter, brands }) => {
  const servicesApiQuto = resolveEnv({
    '*': 'https://stage.quto.ru/unity/v1/catalog/brands/',
    'production': 'https://quto.ru/unity/v1/catalog/brands/',
  });

  return brands || bebopApi
    .getExternal(servicesApiQuto, {
      'attributes[car_brand]': 'base,image',
      'filter[brand]': brandsFilter,
      ...apiParams,
    })
    .then(denormalizeData)
    .catch(() => []);
};
