import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { compose, pure, lifecycle, withStateHandlers } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import GalleryWithAds from 'site/components/GalleryWithAds';
import RcmEventsSender from 'core/components/RcmEventsSender';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';


function TopicMediaGallery(props) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = props.content.relationships;

  return (
    <Fragment>
      {photoGallery && (
        <GalleryWithAds
          adsToReload={[
            {
              name: 'Billboard',
              count: 1,
            },
            {
              name: '240x400',
              count: 1,
            },
          ]}
          hashNavigation
          photoGallery={photoGallery}
          captionCreditsProps={{ position: 'left' }}
        />
      )}
      <RcmEventsSender pageread={{ itemId: props.content.id }} />
    </Fragment>
  );
}

TopicMediaGallery.propTypes = {
  isMediaVisible: PropTypes.bool,
  mediaHandler: PropTypes.func,
  location: PropTypes.object.isRequired,
  content: modelPropTypes(topicAttributes).isRequired,
};

export default compose(
  pure,
  withRouter,
  withStateHandlers(
    () => ({ isMediaVisible: false }),
    { mediaHandler: () => () => ({ isMediaVisible: true }) }
  ),
  lifecycle({
    componentDidMount() {
      const {
        location: {
          hash,
        },
      } = this.props;

      if (hash) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ isMediaVisible: true });
      }
    },
  }),
)(TopicMediaGallery);
