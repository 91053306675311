import cx from 'classnames';

import Button from 'core/components/Button';

import ArrBottom from 'site/icons/ArrBottom';

import styles from './index.styl';
import originalStyles from '../index.styl';

function Skeleton() {
  return (
    <div className={cx(styles.brands, originalStyles.brands)}>
      <div className={originalStyles.wrapper}>
        {Array.from({ length: 30 }).map(() => (
          <div className={cx(styles.brand, 'lb-skeleton')} />
        ))}
      </div>
      <Button
        disabled
        className={originalStyles.button}
        minWidth={0}
      >
        <ArrBottom className={originalStyles.arrow} />
      </Button>
    </div>
  );
}


export default Skeleton;
