import PropTypes from 'prop-types';

import { Block, Section } from 'core/components/Grid';
import { Desktop, Mobile } from 'core/components/breakpoint';
import SideColumn from 'core/components/SideColumn';
import Image from 'core/components/Image';
import TopicTopline from 'core/components/TopicTopline';

import {
  TopicContentIndent,
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import Share from 'site/components/Share';
import SideAdv from 'site/components/SideAdv';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import TopicWrapper from '../TopicWrapper';
import TopicHeader from '../TopicHeader';
import TopicContent from '../TopicContent';
import TopicFooter from '../TopicFooter';
import TopicCoverDesktop from '../TopicCoverDesktop';

const relationships = resolveRelationships(
  ['image', 'content'],
  {},
  {
    image: { versions: {} },
    content: { widgets: [] },
  },
);


function TopicNews({ content, infinityIndex }) {
  const {
    id,
    attributes: {
      link,
      headline,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
      caption,
    },
  } = relationships(content);

  const altAndTitle = caption || headline;

  return (
    <TopicWrapper>
      <Section>
        <Block>
          <TopicContentIndent>
            <VerticalIndent indent={20} />
            <Mobile>
              <TopicTopline />
              <VerticalIndent indent={16} />
            </Mobile>
            <Desktop>
              <TopicTopline />
              <VerticalIndent indent={20} />
            </Desktop>
            <TopicHeader content={content} allowCustomStyles />
            <Mobile>
              <NegativeMobileSideIndent>
                <Image
                  src={cover}
                  previewSrc={previewCover}
                  alt={altAndTitle}
                  title={altAndTitle}
                />
                <VerticalIndent indent={24} />
              </NegativeMobileSideIndent>
            </Mobile>

            <Share
              xid={id}
              topicLink={link}
              isCommentsDisabled={isCommentsDisabled}
            />
          </TopicContentIndent>


          <Desktop>
            <VerticalIndent indent={24} />
            <TopicCoverDesktop
              content={content}
              maxWidth={908}
              maxHeight={600}
            />
          </Desktop>

          <TopicContentIndent>
            <TopicContent content={content} />
            <TopicFooter />
          </TopicContentIndent>
        </Block>
        <SideColumn>
          <SideAdv infinityIndex={infinityIndex} />
        </SideColumn>
      </Section>
    </TopicWrapper>
  );
}

TopicNews.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  infinityIndex: PropTypes.number,
};

export default TopicNews;
