import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import readMore from 'core/components/TopicContent/blocks/readMore';

import Rug from 'site/components/Rug';

import CardReadMore from 'site/cards/CardReadMore';
import { Card2Type0M } from 'site/cards/Card2';


export default function ReadMore({ block }) {
  const contentArr = block && block.attributes ? block.attributes.topic_ids : [];

  if (!contentArr.length) {
    return null;
  }

  return (
    <Rug radius={5} >
      {readMore(
        block,
        {
          title: 'Читайте на тему:',
          singleCard: CardReadMore,
          feedCard: CardReadMore,
          drumCard: Card2Type0M,
        }
      )}
    </Rug>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
};
