import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import AdWrapper from 'core/components/Ad/AdWrapper';
import TopicTopline from 'core/components/TopicTopline';
import ColumnLayout from 'core/components/ColumnLayout';
import Erid from 'core/components/Erid';

import themeSwitcherHOC from 'core/components/themeSwitcher';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import {
  SideIndent,
  VerticalIndent,
  TopicContentIndent,
} from 'site/components/Indents';

import Share from 'site/components/Share';
import { PartnersVertical } from 'site/components/Partners';
import Sponsored from 'site/components/Ads/Sponsored';

import {
  VerticalFirst,
  VerticalSecond,
} from 'site/components/Ads/desktop';

import TopicWrapper from '../TopicWrapper';
import TopicHeader from '../TopicHeader';
import TopicFooter from '../TopicFooter';
import TopicMediaGallery from '../TopicMediaGallery';
import TopicMediaVideo from '../TopicMediaVideo';

const TopicWrapperDark = themeSwitcherHOC('dark')(TopicWrapper);

const topicTypeMapping = {
  gallery: TopicMediaGallery,
  video: TopicMediaVideo,
};

function TopicMedia({ content }) {
  const {
    id,
    attributes: {
      link,
      topic_type: topicType,
      is_comments_disabled: isCommentsDisabled,
      erid,
    },
  } = content;

  const Content = topicTypeMapping[topicType];

  return (
    <Fragment>
      <TopicWrapperDark>
        <Desktop>
          <VerticalIndent indent={25} />
        </Desktop>
        <TopicContentIndent>
          <Mobile>
            <VerticalIndent indent={20} />
            <TopicTopline />
            <VerticalIndent indent={16} />
          </Mobile>
          <Desktop>
            <TopicTopline />
            <VerticalIndent indent={20} />
          </Desktop>
          <TopicHeader
            content={content}
            withAnnounce
            allowCustomStyles
          />
          <VerticalIndent indent={24} />
        </TopicContentIndent>
        <ColumnLayout rightColumn={(<VerticalFirst />)}>
          <Content content={content} />
          <Desktop>
            <TopicContentIndent>
              <VerticalIndent indent={15} />
              <Share
                xid={id}
                topicLink={link}
                isCommentsDisabled={isCommentsDisabled}
              />
              <VerticalIndent indent={15} />
            </TopicContentIndent>
          </Desktop>
        </ColumnLayout>
      </TopicWrapperDark>

      <TopicWrapper>
        <Desktop>
          <VerticalIndent indent={30} />
        </Desktop>
        <ColumnLayout
          rightColumn={(
            <AdWrapper bottom={16}>
              <PartnersVertical />
              <VerticalIndent indent={32} />
              <VerticalSecond />
            </AdWrapper>
          )}
        >
          <Mobile>
            <TopicContentIndent>
              <VerticalIndent indent={15} />
              <Share
                xid={id}
                topicLink={link}
                isCommentsDisabled={isCommentsDisabled}
              />
              <VerticalIndent indent={15} />
            </TopicContentIndent>
          </Mobile>

          <Section>
            <SideIndent desktop={0} />
            <Block>
              <AdWrapper bottom={15}>
                <Sponsored />
              </AdWrapper>
              {erid && <Erid erid={erid} maxWidth='100%' />}
              <TopicFooter />
            </Block>
            <SideIndent desktop={0} />
          </Section>
        </ColumnLayout>
      </TopicWrapper>
    </Fragment>
  );
}

TopicMedia.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
};

export default TopicMedia;
