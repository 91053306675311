import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import {
  VerticalIndent,
} from 'site/components/Indents';
import SideAdv from 'site/components/SideAdv';

import { Card4Type1 } from 'site/cards/Card4';
import { Card2Type1Ml } from 'site/cards/Card2';

import data from './data';

const title = 'Спецпроекты';

function Special(props) {
  const {
    isMobile,
  } = props;

  const card = isMobile ? Card2Type1Ml : Card4Type1;

  return (
    <Page title={title}>
      <PageIndent>
        <ColumnLayout rightColumn={<SideAdv />}>
          <H1>{title}</H1>
          <VerticalIndent indent={30} />
          <Feed
            content={data}
            card={card}
            interitemSpacing={16}
          />
        </ColumnLayout>
      </PageIndent>
    </Page>
  );
}

Special.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Special);
