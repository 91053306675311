import { Fragment } from 'react';
import PropTypes from 'prop-types';
import queryString from 'core/libs/query-string';

import { withRouter } from 'core/libs/router';

import H4 from 'core/components/H4';
import Tag from 'core/components/Tag';
import Tags from 'core/components/Tags';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { VerticalIndent } from 'site/components/Indents';

import { filters as filtersData } from 'site/constants';

function TopicsFilters({
  isDesktop,
  location: {
    pathname,
    search,
  },
}) {
  const filters = queryString.parse(search);

  return (
    <Fragment>
      <H4>Материалы</H4>
      <VerticalIndent indent={isDesktop ? 16 : 11} />
      <Tags>
        {filtersData.map(item => {
          const isCurrent = filters.slug === item.slug
            || !filters.slug && item.type === 'all';

          return (
            <Tag
              key={item.slug}
              minWidth={50}
              type={isCurrent ? 'primary' : 'secondary'}
              link={item.type === 'all'
                ? `${pathname}`
                : `${pathname}?type=${item.type}&slug=${item.slug}`}
            >
              {item.title}
            </Tag>
          );
        })}
      </Tags>
    </Fragment>
  );
}

TopicsFilters.propTypes = {
  /** @ignore */
  isDesktop: PropTypes.bool,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
};

const TopicsFiltersWithHOCs = withRouter(withBreakpoint(TopicsFilters));
TopicsFiltersWithHOCs.displayName = 'TopicsFilters';

export default TopicsFiltersWithHOCs;

export { TopicsFilters as StorybookComponent };
