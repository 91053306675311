import PropTypes from 'prop-types';

import { Desktop, Mobile } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import SideColumn from 'core/components/SideColumn';
import TopicTopline from 'core/components/TopicTopline';

import {
  VerticalIndent,
  TopicContentIndent,
} from 'site/components/Indents';

import Share from 'site/components/Share';
import SideAdv from 'site/components/SideAdv';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import TopicWrapper from '../TopicWrapper';
import TopicHeader from '../TopicHeader';
import TopicContent from '../TopicContent';
import TopicFooter from '../TopicFooter';
import TopicCoverDesktop from '../TopicCoverDesktop';
import TopicCoverMobile from '../TopicCoverMobile';
import PremiumTopic from './premiumTopic';


function TopicArticle({ content, infinityIndex }) {
  const {
    id,
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
      is_premium: isPremiumTopic,
    },
  } = content;


  if (isPremiumTopic) return <PremiumTopic content={content} infinityIndex={infinityIndex} />;

  return (
    <TopicWrapper>
      <Desktop>
        <TopicContentIndent>
          <VerticalIndent indent={20} />
          <TopicTopline />
          <VerticalIndent indent={20} />
          <TopicHeader
            content={content}
            isBig
            allowCustomStyles
          />
          <Share
            xid={id}
            topicLink={link}
            isCommentsDisabled={isCommentsDisabled}
          />
          <VerticalIndent indent={25} />
        </TopicContentIndent>
        <TopicCoverDesktop
          content={content}
          maxWidth={1280}
          hasHorizontalIndents
        />
      </Desktop>

      <Mobile>
        <TopicCoverMobile content={content} infinityIndex={infinityIndex} />
        <VerticalIndent indent={24} />
        <TopicContentIndent>
          <Share
            xid={id}
            topicLink={link}
            isCommentsDisabled={isCommentsDisabled}
          />
        </TopicContentIndent>
      </Mobile>

      <Section>
        <Block>
          <TopicContentIndent>
            <TopicContent content={content} />
            <TopicFooter />
          </TopicContentIndent>
        </Block>
        <SideColumn>
          <VerticalIndent indent={25} />
          <SideAdv infinityIndex={infinityIndex} />
        </SideColumn>
      </Section>
    </TopicWrapper>
  );
}

TopicArticle.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  infinityIndex: PropTypes.number,
};

export default TopicArticle;
