import PropTypes from 'prop-types';

import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import bindPropsHOC from 'core/components/bindProps';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import Mjolnir from 'core/components/Mjolnir';
import Button from 'core/components/Button';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Raker from 'core/components/Raker';
import Drum from 'core/components/Drum';

import Rug from 'site/components/Rug';

import {
  SideIndent,
  InnerIndent,
  VerticalIndent,
  NegativeMobileSideIndent,
} from 'site/components/Indents';

import {
  Native1AsCard1,
  Native2AsCard3Type0,
} from 'site/components/Ads/desktop';

import Card1 from 'site/cards/Card1';
import { Card2Type1Ml, Card2Type0M, Card2Type0Xs } from 'site/cards/Card2';
import Card3, { Card3Type2S, Card3Type3S, Card3Type7S } from 'site/cards/Card3';

import { DRUM_CARD_MAX_WIDTH, pulseBaseUrl } from 'site/constants';

const Card1Type1 = bindPropsHOC({ type: 1 })(Card1);
const Card1Type2 = bindPropsHOC({ type: 2 })(Card1);
const Card1Type3 = bindPropsHOC({ type: 3 })(Card1);
const Card1Type5 = bindPropsHOC({ type: 5 })(Card1);
const Card1Type6 = bindPropsHOC({ type: 6 })(Card1);


export const ListMain = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Mjolnir
        heroCard={bindPropsHOC({ highPriorityImage: true })(Card1Type5)}
        card={Card1Type6}
        interitemSpacing={0}
        content={content}
        cardsReplacement={{
          4: replaceCardWithAd(Native1AsCard1),
        }}
      />
      <VerticalIndent indent={45} />
    </Desktop>
    <Mobile>
      <NegativeMobileSideIndent>
        <Card1Type2 content={content[0]} highPriorityImage />
        {/* параметр Feed'а columns игнорируется на мобилке, поэтому так */}
        <Section>
          <Block>
            <Feed
              card={bindPropsHOC({ imageWidth: 375 })(Card1Type6)}
              content={content.slice(1, 3)}
            />
          </Block>
          <Block>
            <Feed
              card={bindPropsHOC({ imageWidth: 375 })(Card1Type6)}
              content={content.slice(3, 5)}
            />
          </Block>
        </Section>
      </NegativeMobileSideIndent>
    </Mobile>
    <VerticalIndent indent={10} />
  </>
);
ListMain.propTypes = { content: PropTypes.array };


export const ListPulse = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Section>
        <Block desktop={4}>
          <Feed
            card={bindPropsHOC({ imageWidth: 301 })(Card2Type0M)}
            interitemSpacing={16}
            content={content.slice(0, 2)}
          />
        </Block>
        <InnerIndent desktop='16px' />
        <Block>
          <Feed
            card={Card3Type7S}
            interitemSpacing={12}
            content={content.slice(2)}
            cardsReplacement={{
              5: replaceCardWithAd(Native2AsCard3Type0),
            }}
          />
        </Block>
      </Section>
      <VerticalIndent indent={20} />
    </Desktop>
    <Mobile>
      <Feed
        card={Card2Type0M}
        interitemSpacing={10}
        content={content.slice(0, 2)}
      />
      <VerticalIndent indent={10} />
      <div> {/* div для фикса верстки*/}
        <Rug>
          <Feed
            content={content.slice(2)}
            card={Card3Type3S}
            cardsReplacement={{
              [content.slice(2).length - 1]: Card3Type2S,
            }}
          />
          <VerticalIndent indent={10} />
          <div style={{ padding: '0 12px' }}>
            <Link to={pulseBaseUrl}>
              <Button minWidth='100%'>Все новости</Button>
            </Link>
          </div>
          <VerticalIndent indent={10} />
        </Rug>
        <VerticalIndent indent={10} />
      </div>
    </Mobile>
  </>
);
ListPulse.propTypes = { content: PropTypes.array };


export const List3 = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Card1Type5
        imageRatio={16 / 9}
        imageVersion='list_large'
        content={content[0]}
      />
      <Raker
        heroCard={Card1Type2}
        card={Card1}
        content={content.slice(1)}
      />
      <VerticalIndent indent={30} />
    </Desktop>
    <Mobile>
      <NegativeMobileSideIndent>
        <Card1Type5
          imageRatio={16 / 9}
          imageVersion='list_large'
          content={content[0]}
        />
        <VerticalIndent indent={10} />
        <Rug>
          <Card2Type0M content={content[1]} />
          <VerticalIndent indent={20} />
          <Section>
            <SideIndent />
            <Block>
              <Card2Type0Xs imageWidth={359} content={content[2]} />
            </Block>
            <SideIndent />
            <Block>
              <Card2Type0Xs imageWidth={359} content={content[3]} />
            </Block>
            <SideIndent />
          </Section>
          <VerticalIndent indent={10} />
        </Rug>
      </NegativeMobileSideIndent>
    </Mobile>
  </>
);
List3.propTypes = { content: PropTypes.array };


export const ListAdv = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Mjolnir
        heroCard={bindPropsHOC({ imageRatio: 16 / 9, imageVersion: 'list_large' })(Card1Type3)}
        card={Card1}
        content={content}
      />
      <VerticalIndent indent={20} />
    </Desktop>
    <Mobile>
      {/* div для фикса верстки*/}
      <div>
        <Card2Type1Ml content={content[0]} />
        <VerticalIndent indent={8} />
        <Feed
          card={Card3}
          content={content.slice(1)}
          interitemSpacing={8}
        />
        <VerticalIndent indent={8} />
      </div>
    </Mobile>
  </>
);
ListAdv.propTypes = { content: PropTypes.array };

export const List5 = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Raker
        heroCard={bindPropsHOC({ imageRatio: 1, imageVersion: 'card_square' })(Card1Type2)}
        card={bindPropsHOC({ imageRatio: 1.501, imageVersion: 'list_3/2' })(Card1)}
        content={content}
      />
      <VerticalIndent indent={30} />
    </Desktop>
    <Mobile>
      {/* div для фикса верстки*/}
      <div>
        <Card2Type1Ml content={content[0]} />
        <VerticalIndent indent={8} />
        <Feed
          card={Card3}
          content={content.slice(1)}
          interitemSpacing={8}
        />
        <VerticalIndent indent={18} />
      </div>
    </Mobile>
  </>
);
List5.propTypes = { content: PropTypes.array };

export const List7 = withBreakpoint(({ content, isMobile }) => (content?.length > 0 &&
  <Drum
    content={content}
    card={bindPropsHOC({ imageWidth: isMobile ? 256 : 293 })(Card2Type0M)}
    spaceBetween={12}
    itemWidthMobile={DRUM_CARD_MAX_WIDTH}
  />
));

export const List8 = ({ content }) => (content?.length > 0 &&
  <>
    <Desktop>
      <Mjolnir
        heroCard={bindPropsHOC({ imageRatio: 16 / 9, imageVersion: 'list_large' })(Card1Type3)}
        card={bindPropsHOC({ imageRatio: 16 / 9, imageVersion: 'list_large' })(Card1Type1)}
        content={content}
      />
      <VerticalIndent indent={30} />
    </Desktop>
    <Mobile>
      <NegativeMobileSideIndent>
        <Rug>
          <Card2Type0M content={content[0]} />
          <VerticalIndent indent={20} />
          <Section>
            <SideIndent />
            <Block>
              <Card2Type0Xs imageWidth={359} content={content[1]} />
            </Block>
            <SideIndent />
            <Block>
              <Card2Type0Xs imageWidth={359} content={content[2]} />
            </Block>
            <SideIndent />
          </Section>
          <VerticalIndent indent={10} />
        </Rug>
        <VerticalIndent indent={10} />
      </NegativeMobileSideIndent>
    </Mobile>
  </>
);
List8.propTypes = { content: PropTypes.array };
