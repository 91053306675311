import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import TagTopics from 'core/components/TagTopics';

import withPageHocs from 'core/components/withPageHocs';

import { filterRequiredParams } from 'core/utils/api';

import RubricLayout from 'site/components/RubricLayout';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';

import SectionContent from './Content';

const limit = 10;


function TagPage({ tag, topics }) {
  return (
    <RubricLayout>
      <TagTopics
        tag={tag}
        topics={topics}
        limit={limit}
        headerSpacing={0}
      >
        {props => {
          // eslint-disable-next-line react/prop-types
          const { title, titleMeta, descriptionMeta } = props;
          return (
            <>
              <Helmet>
                <title>{titleMeta || `${title}: Новости, тест-драйвы, обзоры`}</title>
                <meta name='description' content={descriptionMeta || `Новости о ${title}: тест-драйвы, обзоры, репортажи, фото и видео на Motor.ru`} />
              </Helmet>
              <SectionContent {...props} />
            </>
          );
        }}
      </TagTopics>
    </RubricLayout>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  topics: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  topics: topicsFetcher({
    limit,
    include: filterRequiredParams([Card1, Card2, Card3, Card4], 'include'),
    fields: filterRequiredParams([Card1, Card2, Card3, Card4], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(TagPage);
