import PropTypes from 'prop-types';

import coreMarkdown from 'core/components/TopicContent/blocks/markdown';

import modelPropTypes from 'core/utils/prop-types/model';

import styles from './index.styl';

export default function Markdown({
  block,
  parentProps,
  ctx,
}) {
  return (
    <div className={styles.markdown}>
      <style jsx>{`
        .${styles.markdown}
          :global(a)
            text-decoration-color ${parentProps.theme.colors.active1}
            &:visited
              text-decoration-color ${parentProps.theme.colors.active1}
      `}</style>
      {coreMarkdown(block, parentProps, ctx)}
    </div>
  );
}

Markdown.propTypes = {
  block: modelPropTypes(
    PropTypes.shape({ body: PropTypes.string.isRequired })
  ).isRequired,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
