import PropTypes from 'prop-types';
import { Fragment } from 'react';

import cx from 'classnames';

import color from 'core/libs/color';
import Image from 'core/components/Image';

import themeHOC from 'core/components/theme';
import themeSwitcherHOC from 'core/components/themeSwitcher';
import TopicTopline from 'core/components/TopicTopline';

import {
  TopicContentIndent,
  VerticalIndent,
  BLOCK_MOBILE_HORIZONTAL,
} from 'site/components/Indents';

import themePropTypes from 'core/utils/prop-types/theme';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import TopicHeader from 'site/pages/topic/TopicHeader';
import TopicCredit from 'site/pages/topic/TopicCredit';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image', 'content'],
  {},
  {
    image: { versions: {} },
    content: { widgets: [] },
  },
);


function TopicCoverMobile(props) {
  const {
    content,
    theme: {
      colors: colorsAtoms,
    },
  } = props;

  const { headline } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  const altAndTitle = caption || headline;

  return (
    <Fragment>
      <style jsx>{`
        .${styles.header}
          background ${colorsAtoms.content}
          padding 14px ${BLOCK_MOBILE_HORIZONTAL}px 0
        .${styles.image}
          margin -30px -${BLOCK_MOBILE_HORIZONTAL}px 0
          :before
            background linear-gradient(
              to top,
              ${color(colorsAtoms.content).alpha(0).string()},
              ${colorsAtoms.content}
            )
      `}</style>
      <div className={styles.header}>
        <TopicContentIndent>
          <TopicTopline />
          <VerticalIndent indent={20} />
          <div className={cx(styles.titles, { [styles.noCover]: !cover })}>
            <TopicHeader content={content} allowCustomStyles />
          </div>
        </TopicContentIndent>
        <div className={styles.image}>
          <Image
            src={cover}
            previewSrc={previewCover}
            placement='contain'
            aspectRatio={1.5}
            useSchema
            alt={altAndTitle}
            title={altAndTitle}
          />
          <TopicCredit
            caption={caption}
            credits={credits}
            creditsUrl={creditsUrl}
          />
        </div>
      </div>
    </Fragment>
  );
}

TopicCoverMobile.propTypes = {
  theme: themePropTypes(`
    {
      colors: {
        content,
      }
    }
  `),
  content: modelPropTypes(topicAttributes).isRequired,
  children: PropTypes.node,
};

export default themeSwitcherHOC('dark')(themeHOC(TopicCoverMobile));
