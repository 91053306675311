import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import {
  relationships,
  SelectionCard,
  requiredPayloadImports as selectionIncludes,
  requiredPayloadFields as selectionFields,
} from 'site/components/Selection';


export default (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    bebopApi,
    consoleError,
    list,
  } = props;

  return bebopApi
    .getTopics({
      sort: 'list',
      limit: 1,
      include: selectionIncludes.join(),
      fields: selectionFields.join(),
      list,
    })
    .then(denormalizeData)
    .then(denormalizedSelections => {
      const selection = denormalizedSelections[0];
      return bebopApi
        .getTopics({
          ids: relationships(selection).read_more.topic_ids,
          include: filterRequiredParams([SelectionCard], 'include'),
          fields: filterRequiredParams([SelectionCard], 'fields'),
        })
        .then(topics => ({
          selection,
          topics: denormalizeData(topics),
        }));
    })
    .catch(consoleError(`${customParams.list} list`));
};
