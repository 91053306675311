import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { isGameCompare } from 'core/components/GameCompare/utils';

import Bouesque from 'core/components/Bouesque';
import StickyPortal from 'core/components/StickyPortal';
import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  VerticalFirst,
  VerticalSecond,
} from 'site/components/Ads/desktop';
import { PartnersVertical, PartnersVerticalGameCompare } from 'site/components/Partners';
import Popular from 'site/components/Popular';
import { VerticalIndent } from 'site/components/Indents';
import SelfPromo from 'core/components/SelfPromo';

function SideAdv({ withBouesque, infinityIndex, location }) {
  const isGameComparePath = isGameCompare(location.pathname);

  return (
    <Fragment>
      {withBouesque && <VerticalIndent indent={14} />}
      {withBouesque && <Bouesque />}
      {withBouesque && <VerticalIndent indent={16} />}
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper top={16}>
          <VerticalFirst />
        </AdWrapper>
        <VerticalIndent indent={16} />
        <SelfPromo />
        <VerticalIndent indent={16} />
        {isGameComparePath ? <PartnersVerticalGameCompare /> : <PartnersVertical />}
      </StickyPortal>
      <StickyPortal>
        <AdWrapper top={16}>
          <VerticalSecond />
        </AdWrapper>
        <VerticalIndent indent={16} />
        {!infinityIndex && (
          <Fragment>
            <Popular />
            <VerticalIndent indent={16} />
          </Fragment>
        )}
      </StickyPortal>
    </Fragment>
  );
}

SideAdv.propTypes = {
  withBouesque: PropTypes.bool,
  location: PropTypes.object,
  infinityIndex: PropTypes.number,
};

SideAdv.defaultProps = {
  withBouesque: true,
};

export default withRouter(SideAdv);
