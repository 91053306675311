import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import TopicToplineSkeleton from 'core/components/TopicTopline/Skeleton';
import SocializatorSkeleton from 'core/components/Socializator/Skeleton';
import TopicHeaderTextsSkeleton from 'core/components/TopicHeaderTexts/Skeleton';

import { TopicContentIndent } from 'site/components/Indents';
import TopicWrapper from 'site/pages/topic/TopicWrapper';

import styles from './index.styl';

function Common({ isMobile }) {
  const indent = isMobile ? 16 : 20;

  return (
    <Fragment>
      <TopicWrapper>
        <TopicContentIndent>
          <Indent
            top={20}
            bottom={indent}
          >
            <TopicToplineSkeleton height={isMobile ? 26 : 22} />
            <Indent top={indent} />
            <TopicHeaderTextsSkeleton
              titleHeight={isMobile ? 60 : 100}
              leadHeight={isMobile ? 150 : 200}
            />
            <Indent top={indent} />
            <Desktop>
              <div className={styles.socializator}>
                <div className={cx('lb-skeleton', styles.button)} />
                <SocializatorSkeleton />
              </div>
            </Desktop>
          </Indent>
        </TopicContentIndent>
      </TopicWrapper>
      <div className={cx('lb-skeleton', styles.cover)} />
    </Fragment>
  );
}

Common.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Common);
