export default {
  0: {
    size: 'm',
  },
  1: {
    size: 'ml',
    mobile: {
      size: 'm',
    },
  },
  2: {
    size: 'l',
    contentAtBottom: true,
    showAltHeadline: true,
    mobile: {
      size: 'ml',
      contentAtBottom: true,
    },
  },
  3: {
    size: 'xl',
    contentAtBottom: true,
    showAltHeadline: true,
    mobile: {
      size: 'l',
      showAltHeadline: true,
      centered: true,
    },
  },
  4: { // TODO: возможно, этот тип карточки не будет использоваться и надо его удалить
    size: 'xl',
    showAltHeadline: true,
    hideIcon: true,
    hideRubric: true,
    centered: true,
    mobile: {
      size: 'l',
      showAltHeadline: true,
      centered: true,
    },
  },
  5: {
    size: 'huge',
    contentAtBottom: true,
    showAltHeadline: true,
    mobile: {
      size: 'l',
      showAltHeadline: true,
      centered: true,
    },
  },
  6: {
    size: 's',
    mobile: {
      size: 'sm',
      hideRubric: true,
    },
  },
};
