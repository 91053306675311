import { Mobile, Desktop } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  Sponsored as DesktopSponsored,
} from 'site/components/Ads/desktop';

import {
  Sponsored as MobileSponsored,
} from 'site/components/Ads/mobile';

export default function Sponsored() {
  return (
    <AdWrapper bottom={15}>
      <Desktop>
        <DesktopSponsored />
      </Desktop>
      <Mobile>
        <MobileSponsored />
      </Mobile>
    </AdWrapper>
  );
}
