import resolveRelationships from 'core/utils/relationships';

import { textReplacer } from 'site/utils';
import { REG_DOUBLE_DASHES, REG_TRIPLE_DASHES } from 'site/constants';

const relationships = resolveRelationships(['rubric', 'seo_meta'], {}, {});

const REGEXES = [
  [REG_DOUBLE_DASHES, '$1—$2'],
  [REG_TRIPLE_DASHES, '—'],
];

export default function titleBuilder(content) {
  const {
    headline,
    alternative_headline: altHeadline,
    topic_type: topicType,
  } = content.attributes;

  const {
    rubric: {
      root_title: rubricRoot,
    },
    seo_meta: {
      title: seoTitle,
    },
  } = relationships(content);

  const commonTitle = seoTitle || altHeadline || headline;

  const titleMetaMap = {
    article: `${textReplacer(commonTitle, REGEXES)} — ${rubricRoot}`,
    gallery: `${textReplacer(commonTitle, REGEXES)} — Фото — ${rubricRoot}`,
    video: `${textReplacer(commonTitle, REGEXES)} — Видео — ${rubricRoot}`,
    news: `${textReplacer(seoTitle || headline, REGEXES)}`,
  };

  return titleMetaMap[topicType] || titleMetaMap.article;
}
