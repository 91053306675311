import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';
import { isGameCompare } from 'core/components/GameCompare/utils';
import withTheme from 'core/components/theme';
import bindPropsHOC from 'core/components/bindProps';

import EmptyWrapper from 'core/components/EmptyWrapper';
import { Indent } from 'core/components/Wrappers';
import ColumnLayout from 'core/components/ColumnLayout';

import Rug from 'site/components/Rug';
import ViewportHeightWrapper from 'site/components/ViewportHeightWrapper';
import SideAdv from 'site/components/SideAdv';

import Common from './Common';
import GameCompare from './GameCompare';

import TopicWrapper from '../TopicWrapper';


const isNews = pathname => /^\/news/.test(pathname);

function Skeleton({ isDesktop, theme, location }) {
  const Content = isGameCompare(location.pathname) ? GameCompare : Common;
  const Wrapper = (isNews(location.pathname) || isGameCompare(location.pathname)) ? TopicWrapper : EmptyWrapper;

  const ContentWrapper = (isDesktop && (isNews(location.pathname) || isGameCompare(location.pathname)))
    ? bindPropsHOC({ rightColumn: (
      <Indent top={14}>
        <SideAdv />
      </Indent>
    ), sideColumnIndent: theme.layout.sideColumnIndent })(ColumnLayout)
    : EmptyWrapper;

  return (
    <ViewportHeightWrapper>
      <Rug radius={0} withoutBorder>
        <Wrapper>
          <ContentWrapper>
            <Content />
          </ContentWrapper>
        </Wrapper>
      </Rug>
    </ViewportHeightWrapper>
  );
}

Skeleton.propTypes = {
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(withBreakpoint(withTheme(Skeleton)));
